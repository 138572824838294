import { Button, Card, Checkbox, DatePicker, Form, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CommonInput from "../../../../Common/CommonInput";
import {
  dispatchtoast,
  emailPattern,
  getAMPM,
  gstPattern,
  mobilePattern,
} from "../../../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FareQuote,
  FareRule,
  FlightTicketbook,
  FlightTicketLcc,
  SSR,
} from "./FlightFun";
import dayjs from "dayjs";

const FlightBook = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [fareRuleSpinner, setFareruleSpinner] = useState(false);
  const [fareQuoteSpinner, setFareQuoteSpinner] = useState(false);
  const [ssrspinner, setSsrSpinner] = useState(false);
  const [FormSpinner, setFormSpinner] = useState(false);
  const [fareRule, setFareRule] = useState([]);
  const [callCount, setCallCount] = useState(0);
  const [fareQuote, setFareQuote] = useState([]);
  const [baggage, setBaggage] = useState(null);
  const [mealDynamic, setMealDynamic] = useState(null);
  const [SeatDynamic, setSeatDynamic] = useState([]);
  const [Meal, setMeal] = useState(null);
  const [flightSSr, setFlightSSr] = useState([]);
  const [listOfCalls, setListOfCalls] = useState([]);
  const [openGstDetail, setOpenGstDetail] = useState(null);
  const passangerCount = useSelector((state) => state.B2B.flightPassangerCount);
  function CreateDynamicForm(n) {
    return Array.from({ length: n }, (_, index) => index + 1);
  }

  useEffect(() => {
    let data = [];
    Object.entries(passangerCount).forEach(([key, val]) => {
      const paxType =
        key === "adult"
          ? "Adults"
          : key === "child"
          ? "Children"
          : key === "infant"
          ? "Infant"
          : null;
      if (paxType !== null) {
        const passengers = Array.from({ length: val }, () => ({
          PaxType: paxType,
        }));
        data = [...data, ...passengers];
      }
    });
    form.setFieldsValue({ fields: data });
  }, []);
  const getFareRules = (count) => {
    setFareruleSpinner(true);
    const formdata = new FormData();
    formdata.append("TraceId", location?.state?.traceId);
    formdata.append(
      "ResultIndex",
      location?.state?.data[callCount]?.resultIndex
    );

    FareRule(formdata)
      .then((res) => {
        if (res.status) {
          setFareRule((prev) => [...prev, res?.data?.fareRules]);
          //   getFareRules(count+1)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setFareruleSpinner(false));
  };
  const getFareQuote = () => {
    setFareQuoteSpinner(true);
    const formdata = new FormData();
    formdata.append("TraceId", location?.state?.traceId);
    formdata.append(
      "ResultIndex",
      location?.state?.data[callCount]?.resultIndex
    );

    FareQuote(formdata)
      .then((res) => {
        if (res.status) {
          setFareQuote((prev) => [...prev, res.data?.results]);
          getSSr();
        }
      })
      .catch((err) => {
        dispatchtoast("fareQuote is not loaded due to some error", true);
        navigate(-1);
      })
      .finally(() => setFareQuoteSpinner(false));
  };
  function getSSr() {
    setSsrSpinner(true);
    const formdata = new FormData();
    formdata.append("TraceId", location?.state?.traceId);
    formdata.append(
      "ResultIndex",
      location?.state?.data[callCount]?.resultIndex
    );

    SSR(formdata)
      .then((res) => {
        if (res.status) {
          setFlightSSr(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSsrSpinner(false));
  }
  useEffect(() => {
    if (callCount <= location?.state?.data?.length - 1) {
      getFareRules();
      getFareQuote();
      setCallCount((prev) => prev + 1);
    }
  }, [callCount]);

  const makePayload = (callindex, val) => {
    const formdata = new FormData();
    const stateData = location?.state?.data[callindex];
    const traceId = location?.state?.traceId;
    const isLCC = stateData?.isLCC;
    const isRefundable = stateData?.isRefundable ? 1 : 0;
    const resultIndex = stateData?.resultIndex;
    const traceResult = stateData?.resultTrace;
    const totalFare =
      fareQuote?.reduce((a, b) => {
        return (a = a + b.fare?.publishedFare);
      }, 0) ?? 0;
    formdata.append("TraceId", traceId);
    formdata.append("isLCC", isLCC);
    formdata.append(
      "total_fare",
      totalFare +
        Number(mealDynamic?.price ?? 0) +
        Number(baggage?.price ?? 0) +
        Number(Meal?.price ?? 0)
    );
    formdata.append(
      "total_base_fare",
      fareQuote?.reduce((a, b) => {
        return (a = a + b.fare?.baseFare);
      }, 0) ?? 0
    );
    formdata.append(
      "total_tax",
      fareQuote?.reduce((a, b) => {
        return (a = a + b.fare?.tax);
      }, 0) ?? 0
    );
    formdata.append("airline", fareRule[callindex][0]?.airline ?? "");
    formdata.append("origin", fareRule[callindex][0]?.origin ?? "");
    formdata.append(
      "destination",
      fareRule[callindex][fareRule[callindex].length - 1]?.destination ?? ""
    );

    formdata.append("isRefundable", isRefundable);
    formdata.append("ResultIndex", resultIndex);
    val.fields.forEach((i, index) => {
      // const checkPassport =
      //   (location?.state?.data[callindex]?.isLCC &&
      //     location?.state?.data[callindex]?.isPassportRequiredAtTicket) ||
      //   (!location?.state?.data[callindex]?.isLCC &&
      //     location?.state?.data[callindex]?.isPassportRequiredAtBook);
      const passangeFare = fareQuote[callindex]?.fareBreakdown?.find(
        (fare) =>
          (fare?.passengerType === 1 && i.PaxType === "Adults") ||
          (fare?.passengerType === 2 && i.PaxType === "Children") ||
          (fare?.passengerType === 3 && i.PaxType === "Infant")
      );
      let fare = {
        currency: passangeFare?.currency,
        baseFare: passangeFare?.baseFare / passangeFare?.passengerCount,
        tax: passangeFare?.tax / passangeFare?.passengerCount,
        yqTax: passangeFare?.yqTax / passangeFare?.passengerCount,
        additionalTxnFeePub:
          passangeFare?.additionalTxnFeePub / passangeFare?.passengerCount,
        additionalTxnFeeOfrd:
          passangeFare?.additionalTxnFeeOfrd / passangeFare?.passengerCount,
      };
      fare = {
        ...fare,
        publishedFare:
          fare?.tax +
          fare?.baseFare +
          fareQuote[callindex]?.fare?.otherCharges / val.fields.length,
      };
      const passengerData = {
        [`Passengers[${index}][Title]`]: i.Title,
        [`Passengers[${index}][FirstName]`]: i.FirstName,
        [`Passengers[${index}][LastName]`]: i.LastName,
        [`Passengers[${index}][DateOfBirth]`]: dayjs(i.DateOfBirth).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        [`Passengers[${index}][PaxType]`]:
          i.PaxType === "Adults"
            ? 1
            : i.PaxType === "Children"
            ? 2
            : i.PaxType === "Infant"
            ? 3
            : 0,
        [`Passengers[${index}][TraceResult]`]: traceResult,
        [`Passengers[${index}][Gender]`]: i.Gender,
        [`Passengers[${index}][GSTCompanyAddress]`]: i.GSTCompanyAddress ?? "",
        [`Passengers[${index}][GSTCompanyContactNumber]`]:
          i.GSTCompanyContactNumber ?? "",
        [`Passengers[${index}][GSTCompanyName]`]: i.GSTCompanyName ?? "",
        [`Passengers[${index}][GSTNumber]`]: i.GSTNumber ?? "",
        [`Passengers[${index}][GSTCompanyEmail]`]: i.GSTCompanyEmail ?? "",
        [`Passengers[${index}][AddressLine1]`]: val.AddressLine1,
        [`Passengers[${index}][AddressLine2]`]: val.AddressLine2,
        [`Passengers[${index}][Fare]`]: JSON?.stringify(fare),
        // JSON.stringify(fareQuote[0]?.fareBreakdown[index]) ?? {},
        [`Passengers[${index}][City]`]: val.City,
        [`Passengers[${index}][CountryCode]`]: val.CountryCode,
        [`Passengers[${index}][CellCountryCode]`]: "+91",
        [`Passengers[${index}][PassportNo]`]: i.PassportNo ? i.PassportNo : "",
        [`Passengers[${index}][PassportExpiry]`]: i.PassportExpiry
          ? dayjs(i.PassportExpiry).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        [`Passengers[${index}][CountryName]`]: val.CountryName ?? "India",
        [`Passengers[${index}][ContactNo]`]: val.ContactNo,
        [`Passengers[${index}][Email]`]: val.Email,
        [`Passengers[${index}][FFAirlineCode]`]:
          fareQuote[callindex]?.airlineCode,
        [`Passengers[${index}][FFNumber]`]: i.FFNumber ?? "",
        [`Passengers[${index}][Meal]`]: Meal
          ? JSON.stringify([Meal])
          : JSON.stringify([]),
        [`Passengers[${index}][Baggage]`]: baggage
          ? JSON.stringify([baggage])
          : JSON.stringify([]),
        [`Passengers[${index}][MealDynamic]`]: mealDynamic
          ? JSON.stringify([mealDynamic])
          : JSON.stringify([]),
        [`Passengers[${index}][SeatDynamic]`]: JSON.stringify([]),
        [`Passengers[${index}][Nationality]`]: val.Nationality ?? "IN",
        [`Passengers[${index}][IsLeadPax]`]: i.IsLeadPax ?? 1,
      };
      for (const [key, value] of Object.entries(passengerData)) {
        formdata.append(key, value);
      }
    });
    return formdata;
  };
  useEffect(() => {
    if (
      (location.state.data?.length == 2 && listOfCalls?.length == 2) ||
      (location?.state?.data?.length == 1 && listOfCalls?.length == 1)
    ) {
      setFormSpinner(true);
      Promise.all(listOfCalls)
        .then(([res1, res2]) => {
          if (listOfCalls?.length == 1) {
            if (res1.status) {
              navigate("/booking-details", {
                state: { bookingId: [res1?.booking_id] },
              });
              dispatchtoast(res1.message);
            }
          } else if (listOfCalls.length === 2) {
            if (res1.status || res2.status) {
              navigate("/booking-details", {
                state: { bookingId: [res1?.booking_id, res2?.booking_id] },
              });
              dispatchtoast(res1.message);
              dispatchtoast(res2.message);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setFormSpinner(false));
    }
  }, [listOfCalls, location.state.data, navigate]);
  const apiCall = (payload, index, val) => {
    const newCall = payload.isLCC
      ? FlightTicketLcc(makePayload(index, val))
      : FlightTicketbook(makePayload(index, val));

    setListOfCalls((prevCalls) => [...prevCalls, newCall]);
  };
  const handleBook = (val) => {
    const AtleastOneISleadPAx = val.fields.find((i) => i.IsLeadPax);
    const rightisLeadPax = val.fields.find(
      (i) => i.PaxType === "Children" && i.IsLeadPax
    );
    if (fareRuleSpinner) {
      dispatchtoast("Wait untill fare rules being loaded", true);
    } else if (!AtleastOneISleadPAx) {
      dispatchtoast(
        "Atleast select one leadpax from passenger from adults",
        true
      );
      return;
    } else if (rightisLeadPax) {
      dispatchtoast("Only an adult person can be Lead Pax", true);
      return;
    } else {
      location?.state?.data.map((payload, index) =>
        apiCall(payload, index, val)
      );
      // const formdata = new FormData();
      // const stateData = location?.state?.data[0];
      // const traceId = location?.state?.traceId;
      // const isLCC = stateData?.isLCC;
      // const isRefundable = stateData?.isRefundable ? 1 : 0;
      // const resultIndex = stateData?.resultIndex;
      // const traceResult = stateData?.resultTrace;
      // const totalFare =
      //   fareQuote?.reduce((a, b) => {
      //     return (a = a + b.fare?.publishedFare);
      //   }, 0) ?? 0;
      // formdata.append("TraceId", traceId);
      // formdata.append("isLCC", isLCC);
      // formdata.append(
      //   "total_fare",
      //   totalFare +
      //     Number(mealDynamic?.price ?? 0) +
      //     Number(baggage?.price ?? 0) +
      //     Number(Meal?.price ?? 0)
      // );
      // formdata.append(
      //   "total_base_fare",
      //   fareQuote?.reduce((a, b) => {
      //     return (a = a + b.fare?.baseFare);
      //   }, 0) ?? 0
      // );
      // formdata.append(
      //   "total_tax",
      //   fareQuote?.reduce((a, b) => {
      //     return (a = a + b.fare?.tax);
      //   }, 0) ?? 0
      // );
      // formdata.append("airline", fareRule[0]?.airline ?? "");
      // formdata.append("origin", fareRule[0]?.origin ?? "");
      // formdata.append("destination", fareRule[0]?.destination ?? "");

      // formdata.append("isRefundable", isRefundable);
      // formdata.append("ResultIndex", resultIndex);
      // val.fields.forEach((i, index) => {
      //   const checkPassport =
      //     (location?.state?.data[0]?.isLCC &&
      //       location?.state?.data[0]?.isPassportRequiredAtTicket) ||
      //     (!location?.state?.data[0]?.isLCC &&
      //       location?.state?.data[0]?.isPassportRequiredAtBook);
      //   const passangeFare = fareQuote[0]?.fareBreakdown?.find(
      //     (fare) =>
      //       (fare?.passengerType === 1 && i.PaxType === "Adults") ||
      //       (fare?.passengerType === 2 && i.PaxType === "Children") ||
      //       (fare?.passengerType === 3 && i.PaxType === "Infant")
      //   );
      //   let fare = {
      //     currency: passangeFare?.currency,
      //     baseFare: passangeFare?.baseFare / passangeFare?.passengerCount,
      //     tax: passangeFare?.tax / passangeFare?.passengerCount,
      //     yqTax: passangeFare?.yqTax / passangeFare?.passengerCount,
      //     additionalTxnFeePub:
      //       passangeFare?.additionalTxnFeePub / passangeFare?.passengerCount,
      //     additionalTxnFeeOfrd:
      //       passangeFare?.additionalTxnFeeOfrd / passangeFare?.passengerCount,
      //   };
      //   fare = {
      //     ...fare,
      //     publishedFare:
      //       fare?.tax + fare?.baseFare + (fareQuote[0]?.fare?.otherCharges/val.fields.length),
      //   };
      //   const passengerData = {
      //     [`Passengers[${index}][Title]`]: i.Title,
      //     [`Passengers[${index}][FirstName]`]: i.FirstName,
      //     [`Passengers[${index}][LastName]`]: i.LastName,
      //     [`Passengers[${index}][DateOfBirth]`]: dayjs(i.DateOfBirth).format(
      //       "YYYY-MM-DDTHH:mm:ss"
      //     ),
      //     [`Passengers[${index}][PaxType]`]:
      //       i.PaxType === "Adults"
      //         ? 1
      //         : i.PaxType === "Children"
      //         ? 2
      //         : i.PaxType === "Infant"
      //         ? 3
      //         : 0,
      //     [`Passengers[${index}][TraceResult]`]: traceResult,
      //     [`Passengers[${index}][Gender]`]: i.Gender,
      //     [`Passengers[${index}][GSTCompanyAddress]`]:
      //       i.GSTCompanyAddress ?? "",
      //     [`Passengers[${index}][GSTCompanyContactNumber]`]:
      //       i.GSTCompanyContactNumber ?? "",
      //     [`Passengers[${index}][GSTCompanyName]`]: i.GSTCompanyName ?? "",
      //     [`Passengers[${index}][GSTNumber]`]: i.GSTNumber ?? "",
      //     [`Passengers[${index}][GSTCompanyEmail]`]: i.GSTCompanyEmail ?? "",
      //     [`Passengers[${index}][AddressLine1]`]: val.AddressLine1,
      //     [`Passengers[${index}][AddressLine2]`]: val.AddressLine2,
      //     [`Passengers[${index}][Fare]`]: JSON?.stringify(fare),
      //     // JSON.stringify(fareQuote[0]?.fareBreakdown[index]) ?? {},
      //     [`Passengers[${index}][City]`]: val.City,
      //     [`Passengers[${index}][CountryCode]`]: val.CountryCode,
      //     [`Passengers[${index}][CellCountryCode]`]: "+91",
      //     [`Passengers[${index}][PassportNo]`]: checkPassport
      //       ? i.PassportNo
      //       : "",
      //     [`Passengers[${index}][PassportExpiry]`]: checkPassport
      //       ? dayjs(i.PassportExpiry).format("YYYY-MM-DDTHH:mm:ss")
      //       : "",
      //     [`Passengers[${index}][CountryName]`]: val.CountryName ?? "India",
      //     [`Passengers[${index}][ContactNo]`]: val.ContactNo,
      //     [`Passengers[${index}][Email]`]: val.Email,
      //     [`Passengers[${index}][FFAirlineCode]`]: fareQuote[0]?.airlineCode,
      //     [`Passengers[${index}][FFNumber]`]: i.FFNumber ?? "",
      //     [`Passengers[${index}][Meal]`]: Meal
      //       ? JSON.stringify([Meal])
      //       : JSON.stringify([]),
      //     [`Passengers[${index}][Baggage]`]: baggage
      //       ? JSON.stringify([baggage])
      //       : JSON.stringify([]),
      //     [`Passengers[${index}][MealDynamic]`]: mealDynamic
      //       ? JSON.stringify([mealDynamic])
      //       : JSON.stringify([]),
      //     [`Passengers[${index}][SeatDynamic]`]: JSON.stringify([]),
      //     [`Passengers[${index}][Nationality]`]: val.Nationality ?? "Indian",
      //     [`Passengers[${index}][IsLeadPax]`]: i.IsLeadPax ?? 1,
      //   };
      //   for (const [key, value] of Object.entries(passengerData)) {
      //     formdata.append(key, value);
      //   }
      // });
      // if (location?.state?.data[0]?.isLCC) {
      //   setFormSpinner(true);
      //   FlightTicketLcc(formdata)
      //     .then((res) => {
      //       if (res.status) {
      //         navigate("/booking-details", {
      //           state: { bookingId: [res.booking_id] },
      //         });
      //         dispatchtoast(res.message);
      //       }
      //     })
      //     .catch((err) => {
      //       if (!err.response.data.status) {
      //         dispatchtoast(err.response.data.error, true);
      //       }
      //     })
      //     .finally(() => setFormSpinner(false));
      // } else {
      //   setFormSpinner(true);
      //   FlightTicketbook(formdata)
      //     .then((res) => {
      //       if (res.status) {
      //         navigate("/booking-details", {
      //           state: { bookingId: [res.booking_id] },
      //         });
      //         dispatchtoast(res.message);
      //       }
      //     })
      //     .catch((err) => {
      //       if (!err.response.data.status) {
      //         dispatchtoast(err.response.data.error, true);
      //       }
      //     })
      //     .finally(() => setFormSpinner(false));
      // }
    }
  };
  const onCheckboxChange = (index) => {
    const fieldsValue = form.getFieldValue("fields");
    const updatedFieldsValue = fieldsValue.map((field, i) => ({
      ...field,
      IsLeadPax: i === index,
    }));
    form.setFieldsValue({ fields: updatedFieldsValue });
  };
  return (
    <>
    <div className="flex justify-end items-center mb-4 text-primary">
      <p onClick={()=>navigate(-1)} className="cursor-pointer underline underline-offset-4 font-bold">Modify Search</p>
    </div>
      <div className="grid grid-cols-7 place-items-start gap-x-3">
        <div className="col-span-7 md:col-span-4 w-full">
          <Spin spinning={FormSpinner}>
            <div className="bg-white max-h-[80vh] overflow-scroll rounded-lg p-3">
              <p className="font-bold">Enter Passanger Detail</p>
              <Form
                onFinish={(val) => handleBook(val)}
                form={form}
                onFinishFailed={(err) => {
                  console.log(err);
                }}
                name="dynamic_form_complex"
                autoComplete="off"
                initialValues={{
                  fields: CreateDynamicForm(
                    Object.values(passangerCount).reduce((a, b) => (a = a + b)),
                    0
                  ),
                }}
              >
                <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter ContatcNo.",
                      },
                      {
                        pattern: mobilePattern,
                        message: "Enter valid mobile.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"ContactNo"}
                    label="Mobile"
                  >
                    <CommonInput placeholder={"Enter Mobile"} />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Address.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"AddressLine1"}
                    label="Address1"
                  >
                    <CommonInput placeholder={"Enter Address"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Address.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"AddressLine2"}
                    label="Address2"
                  >
                    <CommonInput placeholder={"Enter Address"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter email.",
                      },
                      {
                        pattern: emailPattern,
                        whitespace: true,
                        message: "Enter valid email.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"Email"}
                    label="Email"
                  >
                    <CommonInput placeholder={"Enter Email"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Enter Country Name.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"CountryName"}
                    label="CountryName"
                  >
                    <CommonInput placeholder={"Enter Country"} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter City Name.",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    name={"City"}
                    label="City"
                  >
                    <CommonInput placeholder={"Enter City"} />
                  </Form.Item>
                  <div>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Enter Country Code.",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      name={"CountryCode"}
                      label="CountryCode"
                    >
                      <CommonInput placeholder={"Enter Couuntry Code"} />
                    </Form.Item>
                    <p className="p-0 text-xs text-green-700 font-semibold">
                      Eg: IN
                    </p>
                  </div>
                  <div>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Enter Nationality.",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      name={"Nationality"}
                      label="Nationality"
                    >
                      <CommonInput placeholder={"Enter Nationality"} />
                    </Form.Item>
                    <p className="p-0 text-xs text-green-700 font-semibold">
                      Eg: IN
                    </p>
                  </div>
                </div>
                <Form.List name="fields">
                  {(fields) => (
                    <div>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => {
                          return (
                            <>
                              <Card
                                size="small"
                                bordered={false}
                                className="mt-4"
                                title={`Passanger ${name + 1}`}
                                key={key}
                              >
                                <Form.Item
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     whitespace: true,
                                  //     message: "Select Passanger Type.",
                                  //   },
                                  // ]}
                                  className="w-full"
                                  labelCol={{ span: 24 }}
                                  name={[name, "IsLeadPax"]}
                                  fieldKey={[name, "IsLeadPax"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    checked={form.getFieldValue([
                                      "fields",
                                      name,
                                      "IsLeadPax",
                                    ])}
                                    onChange={() => onCheckboxChange(index)}
                                  >
                                    Main Member
                                  </Checkbox>
                                </Form.Item>
                                <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Select Passanger Type.",
                                      },
                                    ]}
                                    className="w-full"
                                    labelCol={{ span: 24 }}
                                    name={[name, "PaxType"]}
                                    fieldKey={[name, "PaxType"]}
                                    label="Passenger Type"
                                  >
                                    <Select
                                      disabled
                                      options={[
                                        {
                                          label: "Adults",
                                          value: "1",
                                        },
                                        {
                                          label: "Children",
                                          value: "2",
                                        },
                                        {
                                          label: "Infant",
                                          value: "3",
                                        },
                                      ]}
                                      placeholder="Select Passanger Type"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Select Title.",
                                      },
                                    ]}
                                    className="w-full"
                                    labelCol={{ span: 24 }}
                                    name={[name, "Title"]}
                                    fieldKey={[name, "Title"]}
                                    label="Title"
                                  >
                                    <Select
                                      value={"Mr"}
                                      options={[
                                        {
                                          label: "Mr",
                                          value: "Mr",
                                        },
                                        {
                                          label: "Ms",
                                          value: "Ms",
                                        },
                                        {
                                          label: "Mrs.",
                                          value: "Mrs",
                                        },
                                      ]}
                                      placeholder="Select Title"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Enter FirstName.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "FirstName"]}
                                    label="FirstName"
                                  >
                                    <CommonInput
                                      placeholder={"Enter First Name"}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Enter LastName.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "LastName"]}
                                    label="LastName"
                                  >
                                    <CommonInput
                                      placeholder={"Enter Last Name"}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select Gender.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "Gender"]}
                                    label="Gender"
                                  >
                                    <Select
                                      options={[
                                        {
                                          label: "Male",
                                          value: 1,
                                        },
                                        {
                                          label: "Female",
                                          value: 2,
                                        },
                                      ]}
                                      placeholder={"Select Gender"}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter DOB.",
                                      },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    name={[name, "DateOfBirth"]}
                                    label="DOB"
                                  >
                                    <DatePicker
                                      className="datepicker"
                                      rootClassName="datepicker"
                                      placeholder={"Enter DOB"}
                                    />
                                  </Form.Item>
                                  {/* {(location?.state?.data[0]?.isLCC &&
                                    location?.state?.data[0]
                                      ?.isPassportRequiredAtTicket) ||
                                    (!location?.state?.data[0]?.isLCC &&
                                      location?.state?.data[0]
                                        ?.isPassportRequiredAtBook && (
                                        <> */}
                                          <Form.Item
                                            // rules={[
                                            //   {
                                            //     required: true,
                                            //     whitespace: true,
                                            //     message: "Enter Passport No.",
                                            //   },
                                            // ]}
                                            labelCol={{ span: 24 }}
                                            name={[name, "PassportNo"]}
                                            label="Passport no"
                                          >
                                            <CommonInput
                                              placeholder={"Enter Passport No"}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            // rules={[
                                            //   {
                                            //     required: true,
                                            //     message:
                                            //       "Enter Passport expiry.",
                                            //   },
                                            // ]}
                                            labelCol={{ span: 24 }}
                                            name={[name, "PassportExpiry"]}
                                            label="Passport Expiry"
                                          >
                                            <DatePicker
                                              className="datepicker"
                                              rootClassName="datepicker"
                                              placeholder={
                                                "Enter Passport Expiry"
                                              }
                                            />
                                          </Form.Item>
                                        {/* </>
                                      ))} */}
                                </div>
                                <div className="flex justify-start items-center mt-2 gap-x-2 font-semibold">
                                  <input
                                    checked={name === openGstDetail}
                                    onChange={() => {
                                      if (name === openGstDetail) {
                                        setOpenGstDetail(null);
                                      } else {
                                        setOpenGstDetail(name);
                                      }
                                    }}
                                    type="checkbox"
                                    placeholder="Gst Detail"
                                  />
                                  <p>Gst Detail</p>
                                </div>
                                {name === openGstDetail && (
                                  <div className="grid grid-cols-1 sm:grid-cols-3  gap-x-4 mt-2">
                                    <Form.Item
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     whitespace: true,
                                      //     message: "Select Passanger Type.",
                                      //   },
                                      // ]}
                                      className="w-full"
                                      labelCol={{ span: 24 }}
                                      initialValue={""}
                                      name={[name, "GSTCompanyAddress"]}
                                      label="Gst Address"
                                    >
                                      <CommonInput
                                        placeholder={"Enter Gst Address"}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      rules={[
                                        {
                                          pattern: mobilePattern,
                                          message: "Enter valid mobile.",
                                        },
                                      ]}
                                      className="w-full"
                                      labelCol={{ span: 24 }}
                                      name={[name, "GSTCompanyContactNumber"]}
                                      fieldKey={[
                                        name,
                                        "GSTCompanyContactNumber",
                                      ]}
                                      label="Gst Contact No"
                                    >
                                      <CommonInput
                                        placeholder={"Enter Gst Contact No"}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     whitespace: true,
                                      //     message: "Enter FirstName.",
                                      //   },
                                      // ]}
                                      labelCol={{ span: 24 }}
                                      name={[name, "GSTCompanyName"]}
                                      initialValue={""}
                                      label="Gst Company Name"
                                    >
                                      <CommonInput
                                        placeholder={"Enter Gst Company Name"}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      rules={[
                                        {
                                          pattern: gstPattern,
                                          message: "Enter valid gst.",
                                        },
                                      ]}
                                      labelCol={{ span: 24 }}
                                      name={[name, "GSTNumber"]}
                                      initialValue={""}
                                      label="Gst Number"
                                    >
                                      <CommonInput
                                        placeholder={"Enter Gst Number"}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      rules={[
                                        {
                                          pattern: emailPattern,
                                          message: "Enter valid email.",
                                        },
                                      ]}
                                      labelCol={{ span: 24 }}
                                      name={[name, "GSTCompanyEmail"]}
                                      initialValue={""}
                                      label="GST Company Email"
                                    >
                                      <CommonInput
                                        placeholder={"Enter Gst Company Email"}
                                      />
                                    </Form.Item>
                                  </div>
                                )}
                              </Card>
                            </>
                          );
                        }
                      )}

                      {/* <Button type="dashed" onClick={() => add()} block>
                      + Add Item
                    </Button> */}
                    </div>
                  )}
                </Form.List>
                <Form.Item className="w-full">
                  <Button
                    style={{ color: "white" }}
                    className="w-full mt-2 bg-primary text-white"
                    htmlType="submit"
                  >
                    Book
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </div>
        <div className="col-span-7 mt-2 md:mt-0 w-full md:col-span-3">
          <Spin spinning={fareQuoteSpinner}>
            <div className="bg-white  max-h-[80vh] overflow-scroll rounded-lg p-3">
              <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
                Fare Summary
              </p>
              <p className="font-medium text-xs rounded-sm mt-2 text-gray-500 ">
                {fareQuote[0]?.fareBreakdown?.find(
                  (i) => i?.passengerType === 1
                )?.passengerCount ?? 0}{" "}
                ADULTS |{" "}
                {fareQuote[0]?.fareBreakdown?.find(
                  (i) => i?.passengerType === 2
                )?.passengerCount ?? 0}{" "}
                CHILDS |{" "}
                {fareQuote[0]?.fareBreakdown?.find(
                  (i) => i?.passengerType === 3
                )?.passengerCount ?? 0}{" "}
                INFANTS
              </p>
              <hr className="mt-2 mb-1" />
              <div className="p-3">
                <div className=" flex justify-between items-center">
                  <p className="w-full text-sm text-gray-600 font-bold">
                    Base Fare
                  </p>
                  <p className="w-full text-lg text-black font-bold  text-end">
                    ₹{" "}
                    {fareQuote?.reduce((a, b) => {
                      return (a = a + b.fare?.baseFare);
                    }, 0) ?? 0}
                  </p>
                </div>
                {fareQuote[0]?.fareBreakdown?.map((i, index) => {
                  const passtype =
                    i.passengerType == 1
                      ? "Adult"
                      : i.passengerType == 2
                      ? "Child"
                      : "Infant";
                  return (
                    <div
                      key={index}
                      className="mt-2 flex justify-between items-center"
                    >
                      <p className="w-full text-sm text-gray-600 font-bold">
                        {passtype} ({i.passengerCount} x{" "}
                        {fareQuote?.length > 1
                          ? (
                              Number(
                                i.baseFare +
                                  fareQuote[fareQuote?.length - 1]
                                    ?.fareBreakdown[index]?.baseFare || 0
                              ) / Number(i?.passengerCount || 1) || 0
                            ).toFixed(2)
                          : Number(i.baseFare) / Number(i?.passengerCount || 1)}
                        )
                      </p>
                      <p className="w-full text-xs text-gray-600 font-bold  text-end">
                        ₹{" "}
                        {fareQuote?.length > 1
                          ? i.baseFare +
                            fareQuote[fareQuote?.length - 1]?.fareBreakdown[
                              index
                            ]?.baseFare
                          : i.baseFare}
                      </p>
                    </div>
                  );
                })}
                <hr className="mt-2" />
                <div className="mt-2 flex justify-between items-center">
                  <p className="w-full text-sm text-black font-bold">
                    Taxes and Surcharges
                  </p>
                  <p className="w-full text-gray-600 font-semibold  text-end">
                    ₹
                    {/* {(fareQuote?.reduce((i, j) => {
                      return (i =
                        i +
                        j?.fareBreakdown?.reduce((a, b) => {
                          return (a = a + b.tax);
                        }, 0));
                    }, 0).toFixed(2)} */}
                    {fareQuote
                      ?.reduce((i, j) => {
                        return (i = i + j?.fare?.tax);
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <p className="w-full text-sm text-black font-bold">
                    Other Charges
                  </p>
                  <p className="w-full text-gray-600 font-semibold  text-end">
                    ₹
                    {fareQuote?.reduce((i, j) => {
                      return (i = i + j?.fare?.otherCharges);
                    }, 0)}
                  </p>
                </div>
                {mealDynamic && (
                  <div className="mt-2 flex justify-between items-center">
                    <p className="w-full text-sm text-black font-bold">
                      MealDynamic
                    </p>
                    <p className="w-full text-gray-600 font-semibold  text-end">
                      ₹{mealDynamic?.price}
                    </p>
                  </div>
                )}
                {baggage && (
                  <div className="mt-2 flex justify-between items-center">
                    <p className="w-full text-sm text-black font-bold">
                      Baggage
                    </p>
                    <p className="w-full text-gray-600 font-semibold  text-end">
                      ₹{baggage?.price}
                    </p>
                  </div>
                )}

                <div className="mt-2 flex justify-between items-center">
                  <p className="w-full text-lg text-primary font-bold">
                    Grand Total:
                  </p>
                  <p className="w-full text-primary font-bold  text-2xl text-end">
                    ₹{" "}
                    {(
                      fareQuote?.reduce((i, j) => {
                        return (i = i + j.fare.publishedFare);
                      }, 0) +
                      Number(mealDynamic?.price ?? 0) +
                      Number(baggage?.price ?? 0)
                    )?.toFixed(2)}
                  </p>
                </div>
              </div>
              <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
                Trip Summary
              </p>
              {fareQuote?.map((item, index1) => {
                return (
                  <div key={index1}>
                    {item?.segments?.map((i, index2) => {
                      return (
                        <div key={index2}>
                          <p className="mt-2 font-bold text-[17px] text-primaryhover underline underline-offset-4  font-mono">{`${
                            item.segments[index2][0]?.origin?.airport?.cityName
                          } - ${
                            item.segments[index2][
                              item.segments[index2]?.length - 1
                            ]?.destination?.airport?.cityName
                          }`}</p>
                          <div className="border-2 border-primary rounded-lg p-2 mt-2">
                            {i.map((item, index3) => (
                              <div
                                key={index3}
                                className="border border-gray-400 p-3  rounded-md mb-1"
                              >
                                <div className="mt-2 flex justify-between items-center">
                                  <p className="w-full text-sm text-black font-bold">
                                    {item?.airline?.airlineName} |{" "}
                                    {item?.airline?.airlineCode}
                                  </p>
                                  <p className="text-[13px] text-green-600 font-bold">
                                    {item?.flightStatus}
                                  </p>
                                </div>
                                <div className="mt-2 flex justify-between items-center">
                                  <p className="w-full text-sm text-black font-bold">
                                    Date:
                                  </p>
                                  <p className="w-full text-gray-600 font-semibold  text-end">
                                    {new Date(
                                      item?.origin?.depTime
                                    )?.toLocaleDateString()}
                                    -
                                    {new Date(
                                      item?.destination?.arrTime
                                    )?.toLocaleDateString()}
                                  </p>
                                </div>
                                <div className="mt-2 flex justify-between items-center">
                                  <p className="w-full text-sm text-black font-bold">
                                    Flight Number:
                                  </p>
                                  <p className="w-full text-gray-600 font-semibold  text-end">
                                    {item?.airline?.flightNumber}
                                  </p>
                                </div>
                                <div className="mt-2 flex justify-between items-center">
                                  <p className="w-full text-sm text-black font-bold">
                                    Departure:
                                  </p>
                                  <p className="w-full text-gray-600 font-semibold  text-end">
                                    {`${item.origin?.airport?.airportName},${item.origin?.airport?.cityName} ${item.origin?.airport?.countryName}`}{" "}
                                    @
                                    {`${new Date(
                                      item?.origin?.depTime
                                    ).getHours()}:${new Date(
                                      item?.origin?.depTime
                                    ).getMinutes()}`}{" "}
                                    {getAMPM(
                                      new Date(item.origin?.depTime).getHours()
                                    )}
                                  </p>
                                </div>
                                <div className="mt-2 flex justify-between items-center">
                                  <p className="w-full text-sm text-black font-bold">
                                    Arrival:
                                  </p>
                                  <p className="w-full text-gray-600 font-semibold  text-end">
                                    {`${item.destination?.airport.airportName} ${item.destination?.airport.airportCode} ${item.destination?.airport.countryName}`}{" "}
                                    @
                                    {`${new Date(
                                      item?.destination?.arrTime
                                    ).getHours()}:${new Date(
                                      item?.destination?.arrTime
                                    ).getMinutes()}`}{" "}
                                    {getAMPM(
                                      new Date(
                                        item.destination?.arrTime
                                      ).getHours()
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Spin>
        </div>
      </div>
      <div className="grid grid-cols-7 mt-2 place-items-start gap-x-3">
        <div className="col-span-7 md:col-span-4 max-h-[400px] overflow-scroll w-full bg-white rounded-lg p-4">
          <Spin spinning={ssrspinner}>
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              SSR Details
            </p>
            <div className="grid grid-cols-2 mt-2 place-items-center gap-3">
              {flightSSr?.meal && flightSSr?.meal?.length > 0 && (
                <div className="w-full">
                  <Select
                    className="w-full"
                    placeholder="Select meal type"
                    dropdownStyle={{ width: "250px" }}
                    options={
                      flightSSr?.meal
                        ? flightSSr?.meal.map((i) => {
                            return {
                              label: (
                                <div
                                  onClick={() => setMeal(i)}
                                  className="flex justify-between items-center"
                                >
                                  <p className="font-bold text-xs">{i.code}:</p>
                                  <p className="text-xs font-semibold">
                                    {i.description}
                                  </p>
                                </div>
                              ),
                              value: i.code,
                            };
                          })
                        : []
                    }
                  />
                </div>
              )}
              {flightSSr?.mealDynamic?.map((i, index) => {
                return (
                  <div className="w-full" key={index}>
                    <Select
                      allowClear
                      onClear={() => setMealDynamic(null)}
                      className="w-full"
                      placeholder="Select MealDynamic"
                      dropdownStyle={{ width: "550px" }}
                      options={
                        i
                          ? i.map((i) => {
                              return {
                                label: (
                                  <div
                                    onClick={() => setMealDynamic(i)}
                                    className="grid grid-cols-9 place-items-center gap-x-4 font-semibold"
                                  >
                                    <p className="text-xs">{i.code}</p>
                                    <p className="text-xs w-1/2">₹ {i.price}</p>
                                    <p className="text-xs w-1/2 break-words col-span-7">
                                      {i.airlineDescription}{" "}
                                    </p>
                                  </div>
                                ),
                                value: i.code,
                              };
                            })
                          : []
                      }
                    />
                  </div>
                );
              })}
              {flightSSr?.baggage?.map((i, index) => {
                return (
                  <div className="w-full" key={index}>
                    <Select
                      allowClear
                      onClear={() => setBaggage(null)}
                      className="w-full"
                      placeholder="Select Baggage"
                      dropdownStyle={{ width: "250px" }}
                      options={
                        i
                          ? i.map((i) => {
                              return {
                                label: (
                                  <div
                                    onClick={() => setBaggage(i)}
                                    className="flex justify-between font-semibold items-center"
                                  >
                                    <p>{i.code}</p>
                                    <p className="text-xs">₹ {i.price}</p>
                                    <p className="text-xs">{i.weight} Kg </p>
                                  </div>
                                ),
                                value: i.code,
                              };
                            })
                          : []
                      }
                    />
                  </div>
                );
              })}
              {/* {flightSSr?.seatDynamic[0]?.segmentSeat[0]?.rowSeats?.map((i, index) => {
                return (
                  <div className="w-full" key={index}>
                    <Select
                      className="w-full"
                      placeholder="Select Baggage"
                      dropdownStyle={{ width: "250px" }}
                      options={
                        i
                          ? i.map((i) => {
                              return {
                                label: (
                                  <div
                                    onClick={() => setBaggage(i)}
                                    className="flex justify-between font-semibold items-center"
                                  >
                                    <p>{i.code}</p>
                                    <p className="text-xs">{i.price} Rs</p>
                                    <p className="text-xs">{i.weight} Kg </p>
                                  </div>
                                ),
                                value: i.code,
                              };
                            })
                          : []
                      }
                    />
                  </div>
                );
              })} */}
            </div>
          </Spin>
        </div>
      </div>
      <div className="grid grid-cols-7 mt-2 place-items-start gap-x-3">
        <div className="col-span-7 md:col-span-4 max-h-[400px] overflow-scroll w-full bg-white rounded-lg p-4">
          <Spin spinning={fareRuleSpinner}>
            <p className="font-bold text-sm rounded-sm mt-2 bg-primary text-white px-4 py-2">
              Fare Rules
            </p>
            {fareRule?.length > 0 ? (
              fareRule.map((i, index) => (
                <div key={index}>
                  {i.map((item, index2) => {
                    return (
                      <div
                        key={index2}
                        className="font-semibold mt-2"
                        dangerouslySetInnerHTML={{
                          __html: item?.fareRuleDetail,
                        }}
                      ></div>
                    );
                  })}
                </div>
              ))
            ) : (
              <p className="font-bold text-center mt-5">No Rule Found</p>
            )}
          </Spin>
        </div>
      </div>
    </>
  );
};

export default FlightBook;
