export const ThemeColor = {
  textgraycolor: "rgba(107,114,128,1)",
  primary: "#fe0000",
  secondary: "#709fba",
  primaryhover: "#ec6060",
  primarydark: "#217069",
  loginbackground: "rgba(91, 207, 197, 0.1)",
  rgbaprimary2: "rgba(91, 207, 197, 0.2)",
  rgbaprimary3: "rgba(91, 207, 197, 0.3)",
  rgbaprimary4: "rgba(91, 207, 197, 0.4)",
  rgbaprimary5: "rgba(91, 207, 197, 0.5)",
  rgbaprimary6: "rgba(91, 207, 197, 0.6)",
  rgbaprimary7: "rgba(91, 207, 197, 0.7)",
  rgbaprimary8: "rgba(91, 207, 197, 0.8)",
  rgbaprimary9: "rgba(91, 207, 197, 0.9)",
  bsblue: "#5e72e4",
  bsindigo: "#6610f2",
  bspurple: "#6f42c1",
  bspink: "#e83e8c",
  bsred: "#EE3232",
  bsorange: "#ff9900",
  bsyellow: "#FFFA6F",
  bsgreen: "#297F00",
  bsteal: "#20c997",
  bscyan: "#3065D0",
  bswhite: "#fff",
  bsgray: "#6c757d",
  bsgraydark: "#343a40",
  bsgray100: "#f8f9fa",
  bsgray200: "#e9ecef",
  bsgray300: "#dee2e6",
  bsgray400: "#ced4da",
  bsgray500: "#adb5bd",
  bsgray600: "#6c757d",
  bsgray700: "#495057",
  bsgray800: "#343a40",
  bsgray900: "#212529",
  bsprimary: "#5bcfc5",
  bssecondary: "#709fba",
  bssuccess: "#68e365",
  bsinfo: "#b48dd3",
  bswarning: "#ffa755",
  bsdanger: "#f72b50",
  bslight: "#c8c8c8",
  bsdark: "#6e6e6e",
  bsprimaryrgb: "rgb(91, 207, 197)",
  bssecondaryrgb: "rgb(112, 159, 186)",
  bssuccessrgb: "rgb(104, 227, 101)",
  bsinforgb: "rgb(180, 141, 211)",
  bswarningrgb: "rgb(255, 167, 85)",
  bsdangerrgb: "rgb(247, 43, 80)",
  bslightrgb: "rgb(200, 200, 200)",
  bsdarkrgb: "rgb(110, 110, 110)",
  bswhitergb: "rgb(255, 255, 255)",
  bsblackrgb: "rgb(0, 0, 0)",
  bsbodycolorrgb: "rgb(150, 155, 160)",
  bsbodybgrgb:" rgb(246, 246, 246)",
  //  bsgradient: lineargradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)),
  bsbodycolor: "#969ba0",
  bsbodybg: "#f6f6f6",
};