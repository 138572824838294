import React, { useEffect, useState } from "react";
import { dummydata } from "./FlightEndpoint";
import FlightDetailDrawer from "./FlightDetailDrawer";
import { trimString } from "../../../../Utils";
import { Radio, Tooltip } from "antd";
import { Images } from "../../../../Controller";
import { useNavigate } from "react-router-dom";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
dayjs.extend(duration);
const FlightRoundWayLists = ({ data, key = 0 }) => {
  const navigate = useNavigate();
  const [OpenDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [detail, setDetail] = useState(null);
  const [Origin, setOrigin] = useState(null);
  const [selected, setSelected] = useState({
    origin: null,
    destination: null,
  });
  const [Destination, setDestination] = useState(null);
  function convertMinutesToTimeString(start, end) {
    const startTime = dayjs(start);
    const endTime = dayjs(end);

    const diff = endTime.diff(startTime);
    const durationObj = dayjs.duration(diff);

    const hours = Math.floor(durationObj.asHours());
    const minutes = durationObj.minutes();

    const formattedDuration = `${hours}h ${minutes}m`;
    return formattedDuration;
  }

  useEffect(() => {
    if (data.result?.length > 0) {
      const resultIndexOrigin = data?.result?.[0]?.[0]?.resultIndex;
      const resultIndexDestination = data?.result?.[1]?.[0]?.resultIndex;
      setSelected({
        origin: resultIndexOrigin,
        destination: resultIndexDestination,
      });
      setOrigin(data?.result?.[0]?.[0]);
      setDestination(data?.result?.[1]?.[0]);
    }
  }, []);
  useEffect(() => {}, [Origin, Destination, selected]);
  return (
    <>
      {data?.result.length > 0 && (
        <div className="grid sticky bg-white mt-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-3 p-3">
          <div className="grid grid-cols-4 w-full place-items-center">
            <div className="flex flex-col justify-center items-center gap-x-2">
              <img
                loading="lazy"
                onError={(e) => {
                  e.target.src = Images.FlightImage;
                  e.target.alt = "noImage";
                }}
                src={
                  Origin?.airlineLogo ? Origin?.airlineLogo : Images.FlightImage
                }
                className="w-6"
                alt="flight "
              />
              <div>
                <Tooltip title="hiii">
                  <p className="text-sm cursor-pointer font-bold">
                    {trimString(
                      Origin?.segments[0][0]?.airline?.airlineName,
                      8
                    )}
                  </p>
                </Tooltip>
              </div>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-400">
                {new Date(
                  Origin?.segments[0][0]?.origin?.depTime
                ).toDateString()}{" "}
              </p>
              <p className="text-gray-500">
                {Origin?.segments[0][0]?.origin?.airport?.cityCode}
              </p>
              <p className="text-lg font-bold">
                {" "}
                {new Date(Origin?.segments[0][0]?.origin?.depTime).getHours() +
                  ":" +
                  new Date(
                    Origin?.segments[0][0]?.origin?.depTime
                  ).getMinutes()}
              </p>
            </div>
            <div className="text-xs w-full text-gray-500">
              <p>
                {convertMinutesToTimeString(
                  Origin?.segments[0][0]?.origin?.depTime,
                  Origin?.segments[0][Origin.segments[0]?.length - 1]
                    ?.destination?.arrTime
                )}
              </p>
              <p>{Origin?.segments[0].length - 1} Stop</p>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-400">
                {new Date(
                  Origin?.segments[0][0]?.destination?.arrTime
                ).toDateString()}{" "}
              </p>
              <p className="text-gray-500">
                {Origin?.segments[0][0]?.destination?.airport?.cityCode}
              </p>
              <p className="text-lg font-bold">
                {" "}
                {new Date(
                  Origin?.segments[0][0]?.destination?.arrTime
                ).getHours() +
                  ":" +
                  new Date(
                    Origin?.segments[0][0]?.destination?.arrTime
                  ).getMinutes()}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 w-full border-dashed border-l place-items-center">
            <div className="flex flex-col justify-center items-center gap-x-2">
              <img
                loading="lazy"
                onError={(e) => {
                  e.target.src = Images.FlightImage;
                  e.target.alt = "noImage";
                }}
                src={
                  Destination?.airlineLogo
                    ? Destination?.airlineLogo
                    : Images.FlightImage
                }
                className="w-6"
                alt="flight "
              />
              <div>
                <Tooltip title="hiii">
                  <p className="text-sm cursor-pointer font-bold">
                    {trimString(
                      Destination?.segments[0][0]?.airline?.airlineName,
                      8
                    )}
                  </p>
                </Tooltip>
              </div>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-400">
                {new Date(
                  Destination?.segments[0][0]?.origin?.depTime
                ).toDateString()}{" "}
              </p>
              <p className="text-gray-500">
                {Destination?.segments[0][0]?.origin?.airport?.cityCode}
              </p>
              <p className="text-lg font-bold">
                {" "}
                {new Date(
                  Destination?.segments[0][0]?.origin?.depTime
                ).getHours() +
                  ":" +
                  new Date(
                    Destination?.segments[0][0]?.origin?.depTime
                  ).getMinutes()}
              </p>
            </div>
            <div className="text-xs w-full text-gray-500">
              <p>
                {convertMinutesToTimeString(
                  Destination?.segments[0][0]?.origin?.depTime,
                  Destination?.segments[0][Destination.segments[0]?.length - 1]
                    ?.destination?.arrTime
                )}
              </p>
              <p>{Destination?.segments[0].length - 1} Stop</p>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-400">
                {new Date(
                  Destination?.segments[0][0]?.destination?.arrTime
                ).toDateString()}{" "}
              </p>
              <p className="text-gray-500">
                {Destination?.segments[0][0]?.destination?.airport.cityCode}
              </p>
              <p className="text-lg font-bold">
                {" "}
                {new Date(
                  Destination?.segments[0][0]?.destination?.arrTime
                ).getHours() +
                  ":" +
                  new Date(
                    Destination?.segments[0][0]?.destination?.arrTime
                  ).getMinutes()}
              </p>
            </div>
          </div>
          <div className="border-dashed border-l w-full flex  justify-around items-center">
            <p className="font-bold text-lg">
              ₹ {Origin?.fare?.baseFare + Destination?.fare?.baseFare}
            </p>
            <button
              onClick={() =>
                navigate("/flight-book", {
                  state: {
                    data: [Origin, Destination],
                    traceId: data.traceId,
                  },
                })
              }
              className="text-sm font-bold bg-primary text-white rounded-md px-4 py-1"
            >
              BOOK
            </button>
          </div>
        </div>
      )}
      <div
        className={`mt-2  w-full  rounded-lg grid grid-cols-2 place-items-start md:place-items-start gap-4`}
      >
        {data?.result?.length > 0 &&
          data?.result?.map((i, index) => {
            return (
              <div
                key={index}
                className="w-full rounded-sm shadow-sm shadow-gray-200 mt-2"
              >
                {i.map((item, index2) => {
                  const Origin = item?.segments[0][0]?.origin?.airport;
                  const OriginDeparture = item?.segments[0][0]?.origin;
                  const Destination =
                    item?.segments[0][item.segments[0]?.length - 1]?.destination
                      ?.airport;
                  const DestinationArrival =
                    item?.segments[0][item.segments[0]?.length - 1]
                      ?.destination;
                  const airline = item?.segments[0][0]?.airline;
                  return (
                    <div
                      key={index2}
                      className="grid relative grid-cols-6 place-items-start items-center bg-white mt-4 p-4 gap-x-3"
                    >
                      <p className="absolute right-2 top-0 text-xs text-green-800">
                        {item?.isLCC ? "LCC" : "Non-LCC"}
                      </p>
                      <div>
                        <div className="flex flex-col justify-center items-center gap-x-2">
                          <img
                            onError={(e) => {
                              e.target.src = Images.FlightImage;
                              e.target.alt = "noImage";
                            }}
                            src={
                              item?.airlineLogo
                                ? item?.airlineLogo
                                : Images.FlightImage
                            }
                            className="w-6"
                            alt="flight "
                          />
                          <div>
                            <Tooltip title={airline?.airlineName}>
                              <p className="text-sm cursor-pointer font-bold">
                                {trimString(airline?.airlineName, 8)}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="text-lg font-bold">
                          <span className="font-semibold text-sm">
                            {Origin?.cityCode}
                          </span>{" "}
                        </p>
                        <p className="font-semibold text-sm text-gray-500">
                          {new Date(OriginDeparture?.depTime).getHours() +
                            ":" +
                            new Date(OriginDeparture?.depTime).getMinutes()}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500 font-bold">
                          {convertMinutesToTimeString(
                            OriginDeparture?.depTime,
                            DestinationArrival?.arrTime
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg font-bold">
                          <span className="font-semibold text-xs">
                            {Destination?.cityCode}
                          </span>{" "}
                        </p>
                        <p className="font-semibold text-sm text-gray-500">
                          {new Date(DestinationArrival?.arrTime).getHours() +
                            ":" +
                            new Date(DestinationArrival?.arrTime).getMinutes()}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-bold">
                          ₹ {item?.fare?.baseFare ?? 0}
                        </p>
                        <p className="text-xs text-red-600">
                          {item?.segments[0][0]?.noOfSeatAvailable} Seat Left
                        </p>
                      </div>
                      <div className="flex justify-center items-center flex-col">
                        <Radio
                          checked={
                            item.resultIndex === selected.origin ||
                            item.resultIndex === selected.destination
                          }
                          value={item.resultIndex}
                          onChange={(val) =>
                            index === 0
                              ? (setSelected({
                                  ...selected,
                                  origin: item.resultIndex,
                                }),
                                setOrigin(item))
                              : (setSelected({
                                  ...selected,
                                  destination: item.resultIndex,
                                }),
                                setDestination(item))
                          }
                        />
                        <p
                          onClick={() => {
                            setDetail(item?.segments[0]);
                            setOpenDetailDrawer(true);
                          }}
                          className="font-semibold text-xs mt-2 cursor-pointer text-primary"
                        >
                          Flight Details{" "}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      {OpenDetailDrawer && (
        <FlightDetailDrawer
          open={OpenDetailDrawer}
          setOpen={setOpenDetailDrawer}
          detail={detail}
        />
      )}
    </>
  );
};

export default FlightRoundWayLists;
