import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Radio,
  Select,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrFormSubtract, GrFormAdd } from "react-icons/gr";

import { FaSearch } from "react-icons/fa";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { MdFlightTakeoff } from "react-icons/md";
import Tabbar from "../Tabbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AirportSearch, FlightSearch, GetCalenderFare } from "./FlightFun";
import { convertDate } from "../../../../Utils";
import FlightLoader from "../../../../Common/FlightLoader";
import FlightLists from "./FlightLists";
import FlightRoundWayLists from "./FlightRoundWayList";
import { Images } from "../../../../Controller";
import {
  setFlightPassangerCount,
  setFlightSearchDetail,
  setFlightSearchResult,
} from "../../../../Store/B2bslice";
import dayjs from "dayjs";
const Flights = () => {
  const dispatch = useDispatch();
  const [childrens, setChildrens] = useState(0);
  const [adults, setAdults] = useState(1);
  const [infant, setInfant] = useState(0);
  const [flightResult, setFlightResult] = useState({
    result: [],
    traceId: "",
  });
  const [airportList, setairportList] = useState([]);
  const [searchStr, setSearchStr] = useState({
    value: "",
    index: 0,
    type: "",
  });
  const [flightStop, setFlightstop] = useState([]);
  const [bookingType, setBookingType] = useState(1);
  const [calenderData, setCalenderData] = useState({});
  const [pageLoader, setPageLoader] = useState(false);
  const [chooseReturn, setChooseReturn] = useState(false);
  const [calenderLoader, setCalenderLaoder] = useState(false);
  const [multicityList, setMultiCityList] = useState([
    {
      Origin: { name: "", value: "" },
      Destination: { name: "", value: "" },
      FlightCabinClass: 1,
      PreferredDepartureTime: null,
      PreferredArrivalTime: null,
      returnDate: null,
    },
  ]);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const FilledDetail = useSelector((state) => state.B2B.flightSearchDetail);
  console.log({multicityList},{FilledDetail})
  // const FlightSearchResult = useSelector((state) => state.B2B.flightSearchResult);

  const passangerCount = useSelector((state) => state.B2B.flightPassangerCount);
  const navigate = useNavigate();
  const TravelClass = [
    { label: "All", value: 1 },
    { label: "Economy", value: 2 },
    { label: "Premium Economy", value: 3 },
    { label: "Business", value: 4 },
    { label: "Premium Business", value: 5 },
    { label: "First Class", value: 6 },
  ];

  const handleSubtract = (type) => {
    if (type === "adult" && adults > 0) {
      setAdults(adults - 1);
    } else if (type === "children" && childrens > 0) {
      setChildrens(childrens - 1);
    } else if (type === "infant" && infant > 0) {
      setInfant(infant - 1);
    }
  };
  const handleAdd = (type) => {
    if (type === "adult") {
      setAdults(adults + 1);
    } else if (type === "children") {
      setChildrens(childrens + 1);
    } else {
      setInfant(infant + 1);
    }
  };
  useEffect(() => {
    if (bookingType === 1) {
      setChooseReturn(false);
    } else if (bookingType === 2) {
      setChooseReturn(true);
    } else {
      setChooseReturn(false);
    }
  }, [bookingType]);
  useEffect(() => {
    if (FilledDetail && FilledDetail?.length === 1) {
      setBookingType(1);
    } else if (FilledDetail && FilledDetail?.length === 2) {
      setBookingType(2);
    } else if (FilledDetail && FilledDetail?.length > 2) {
      setBookingType(3);
    }
    if (passangerCount) {
      setAdults(passangerCount?.adult);
      setChildrens(passangerCount?.child);
      setInfant(passangerCount?.infant);
    }
    if (FilledDetail && FilledDetail?.length > 0) {
      setMultiCityList(FilledDetail);
    }
  }, []);
  const handleRemove = (index) => {
    let newdata = [...multicityList];
    newdata.splice(index, 1);
    setMultiCityList(newdata);
  };
  useEffect(() => {
    if (!memberServices.includes("14")) {
      navigate("/not-Available");
    }
  }, []);
  useEffect(() => {
    if (!FilledDetail &&FilledDetail?.length <= 0) {
      setMultiCityList([
        {
          Origin: "",
          Destination: "",
          FlightCabinClass: 1,
          PreferredDepartureTime: "",
          PreferredArrivalTime: "",
          returnDate: null,
        },
      ]);
    }
    setFlightResult({
      result: [],
      traceId: "",
    });
  }, [bookingType]);
  const handleGetFare = (date = convertDate(new Date().toISOString())) => {
    setCalenderLaoder(true);
    const formdata = new FormData();
    formdata.append("JourneyType", bookingType);
    formdata.append("Origin", multicityList[0].Origin.value);
    formdata.append("Destination", multicityList[0].Destination.value);
    formdata.append("FlightCabinClass", multicityList[0].FlightCabinClass);
    formdata.append("PreferredDepartureTime", date);

    GetCalenderFare(formdata)
      .then((res) => {
        if (res.status) {
          setCalenderData(
            res.data.searchResults.reduce((a, b) => {
              let date = b.departureDate?.split("T")[0];
              a = { ...a, [date]: b.baseFare };
              return a;
            }, {})
          );
          // setCalenderFareResult(res.data.searchResults);
          // setOpenFareDrawer(true);
        } else {
          setCalenderData({});
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setCalenderLaoder(false));
  };
  const handleSearch = () => {
    setFlightResult({
      result: [],
      traceId: "",
    });
    setPageLoader(true);
     dispatch(
      setFlightPassangerCount({
        adult: adults,
        child: childrens,
        infant: infant,
      })
    );
    dispatch(setFlightSearchDetail(multicityList));
   
    let data;
    if (bookingType === 2) {
      data = [
        ...multicityList,
        {
          Origin: multicityList[0].Destination,
          Destination: multicityList[0].Origin,
          FlightCabinClass: 1,
          PreferredDepartureTime: multicityList[0].returnDate,
          PreferredArrivalTime: multicityList[0].returnDate,
        },
      ];
    } else {
      data = [...multicityList];
    }
    const formdata = new FormData();
    formdata.append("JourneyType", bookingType);
    formdata.append("AdultCount", adults);
    formdata.append("ChildCount", childrens);
    formdata.append("InfantCount", infant);
    formdata.append("DirectFlight", flightStop.includes("DirectFlight"));
    formdata.append("OneStopFlight", flightStop.includes("OneStopFlight"));

    data.forEach((item, index) => {
      formdata.append(`Segments[${index}][Origin]`, item.Origin.value);
      formdata.append(
        `Segments[${index}][Destination]`,
        item.Destination.value
      );
      formdata.append(
        `Segments[${index}][FlightCabinClass]`,
        item.FlightCabinClass
      );
      formdata.append(
        `Segments[${index}][PreferredDepartureTime]`,
        item.PreferredDepartureTime?item.PreferredDepartureTime:dayjs(new Date()).format('YYYY-MM-DDT00:00:00')
      );
      formdata.append(
        `Segments[${index}][PreferredArrivalTime]`,
        item.PreferredArrivalTime?item.PreferredArrivalTime:dayjs(new Date()).format('YYYY-MM-DDT00:00:00')
      );
    });
    FlightSearch(formdata)
      .then((res) => {
        if (res.status) {
          setFlightResult({
            result: res.data.results,
            traceId: res.data.traceId,
          });
          // dispatch(setFlightSearchResult({
          //   result: res.data.results,
          //   traceId: res.data.traceId,
          // }))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };


  useEffect(() => {
    if (
      (bookingType === 1 || bookingType === 2) &&
      multicityList.length === 1 &&
      multicityList[0].Origin.value &&
      multicityList[0].Destination.value
    ) {
      handleGetFare(
        multicityList[0].PreferredDepartureTime ||
          convertDate(new Date().toISOString())
      );
    }
  }, [multicityList[0].Origin.value, multicityList[0].Destination.value]);
  useEffect(() => {
    let delayDebounce;

    if (searchStr.value?.trim() !== "") {
      delayDebounce = setTimeout(() => {
        const formdata = new FormData();
        formdata.append("airport_name", searchStr.value);
        AirportSearch(formdata)
          .then((res) => {
            setairportList(
              res?.data.map((i, index) => {
                return {
                  value: i.code,
                  label: (
                    <>
                      <div
                        onClick={() => {
                          let data = [...multicityList];
                          data[searchStr.index] = {
                            ...multicityList[searchStr.index],
                            [searchStr.type]: { name: i.name, value: i.code },
                          };
                          setMultiCityList(data);
                          setairportList([]);
                          setSearchStr("");
                        }}
                        key={index}
                        className="flex justify-between items-center gap-x-4"
                      >
                        <div className="flex justify-start items-center gap-x-3">
                          <p>✈</p>
                          <p>{i.name}</p>
                        </div>
                        <p>{i.code}</p>
                      </div>

                      <hr className="m-0" />
                    </>
                  ),
                };
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500); // Adjust the delay as needed (e.g., 500ms)
    }
    // else {
    //   setairportList([
    //     {
    //       value: "",
    //       label: (
    //         <div className="text-center mt-2">
    //           <p className="fw-bold m-0" style={{ fontSize: "12px" }}>
    //             No data
    //           </p>
    //         </div>
    //       ),
    //     },
    //   ]);
    // }

    // return () => clearTimeout(delayDebounce)
  }, [searchStr]);
  const dateRender = (currentDate) => {
    const dateString = currentDate.format("YYYY-MM-DD");
    return (
      <Spin spinning={calenderLoader}>
        <div className="ant-picker-cell-inner ">
          <div>{currentDate.date()}</div>
          {calenderData[dateString] ? (
            <div className="custom-content text-xs p-0 text-green-700 font-semibold">
              {calenderData[dateString]}
            </div>
          ) : (
            <p>-</p>
          )}
        </div>
      </Spin>
    );
  };

  return (
    <>
      <Spin indicator={<FlightLoader />} spinning={pageLoader}>
        <Tabbar from={"flights"} />
        <div className=" p-4 bg-white rounded-xl">
          <div className="flex justify-start items-center gap-3">
            <MdFlightTakeoff className="text-primary" size={20} />
            <p className="font-semibold text-xl">Flight Booking</p>
          </div>
          <div className="grid grid-cols-2 place-items-start mt-3">
            <div className="radio-group ">
              <Radio.Group
                value={bookingType}
                onChange={(e) => setBookingType(e.target.value)}
                name="radiogroup"
                defaultValue={1}
              >
                <Radio value={1}>One Way</Radio>
                <Radio value={2}>Round Trip</Radio>
                {/* <Radio value={3}>Multicity</Radio> */}
              </Radio.Group>
            </div>
          </div>
          <div className="mt-2">
            <Checkbox.Group
              options={["DirectFlight", "OneStopFlight"]}
              onChange={(val) => setFlightstop(val)}
            />
          </div>

          {bookingType === 1 || bookingType === 2 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-6 ">
              <div className="flex justify-around items-center font-semibold w-full gap-4">
                <div className="w-full">
                  <AutoComplete
                    dropdownStyle={{ width: "450px" }}
                    placeholder="From ✈"
                    className="w-full flight mt-2"
                    placement="bottomLeft"
                    value={multicityList[0].Origin.name}
                    onSearch={(value) => {
                      let update = {
                        ...multicityList[0],
                        Origin: { name: value, value: "" },
                      };
                      setMultiCityList([update]);
                      setSearchStr({ value: value, index: 0, type: "Origin" });
                    }}
                    options={airportList}
                  />
                </div>
                <HiOutlineSwitchHorizontal
                  className="cursor-pointer text-primary font-extralight mt-4"
                  size={40}
                />
                <div className="w-full">
                  <AutoComplete
                    dropdownStyle={{ width: "450px" }}
                    placeholder="To ✈"
                    className="w-full mt-2"
                    value={multicityList[0].Destination.name}
                    onSearch={(value) => {
                      let update = {
                        ...multicityList[0],
                        Destination: { name: value, value: "" },
                      };
                      setMultiCityList([update]);
                      setSearchStr({
                        value: value,
                        index: 0,
                        type: "Destination",
                      });
                    }}
                    options={airportList}
                  />
                </div>
              </div>
              <div className="flex justify-around items-center font-semibold w-full gap-4">
                <div className="w-full ">
                  <DatePicker
                    allowClear={false}
                    rootClassName="datepicker "
                    dateRender={dateRender}
                    popupClassName="bottom-calendar"
                    className="w-full datepicker mt-2"
                    // onPanelChange={(val) => {
                    //   if (
                    //     multicityList[0].Origin.value &&
                    //     multicityList[0].Destination.value
                    //   ) {
                    //     const currentMonth = new Date().getMonth();
                    //     const selectedDate = new Date(val.$d);

                    //     const getAdjustedDate = (date, isCurrentMonth) => {
                    //       const adjustedDate = new Date(date);
                    //       adjustedDate.setDate(
                    //         isCurrentMonth ? new Date().getDate() : 1
                    //       );
                    //       return adjustedDate;
                    //     };

                    //     const adjustedDate = getAdjustedDate(
                    //       selectedDate,
                    //       val.$M === currentMonth
                    //     );
                    //     handleGetFare(convertDate(adjustedDate.toISOString()));
                    //   }
                    // }}
                    value={
                      multicityList[0]?.PreferredDepartureTime
                        ? dayjs(multicityList[0]?.PreferredDepartureTime)
                        : dayjs(new Date())
                    }
                    onChange={(val) => {
                      let temp = [...multicityList];
                      temp[0] = {
                        ...multicityList[0],
                        PreferredDepartureTime: convertDate(
                          new Date(val.$d).toISOString()
                        ),
                        PreferredArrivalTime: convertDate(
                          new Date(val.$d).toISOString()
                        ),
                      };
                      setMultiCityList(temp);
                      // if (
                      //   multicityList[0].Origin.value &&
                      //   multicityList[0].Destination.value
                      // ) {
                      //   handleGetFare(
                      //     convertDate(new Date(val.$d).toISOString())
                      //   );
                      // }
                    }}
                    placement="bottomRight"
                    placeholder="Depart"
                  />
                </div>
                <div className="w-full cursor-pointer">
                  {chooseReturn ? (
                    <DatePicker
                      allowClear={false}
                      rootClassName="datepicker "
                      dropdownClassName="bottom-calendar"
                      className="w-full datepicker mt-2"
                      value={
                        multicityList[0]?.returnDate
                          ? dayjs(multicityList[0]?.returnDate)
                          : dayjs(new Date())
                      }
                      onChange={
                        (val) => {
                          let temp = [...multicityList];
                          temp[0] = {
                            ...multicityList[0],
                            returnDate: convertDate(
                              new Date(val.$d).toISOString()
                            ),
                          };
                          setMultiCityList(temp);
                        }
                        // setReturnDate(
                        //   convertDate(new Date(val.$d).toISOString())
                        // )
                      }
                      placeholder="Return"
                    />
                  ) : (
                    <div className="border border-bsgray400 h-8 mt-2  text-bsgray flex flex-wrap overflow-y-scroll justify-around items-center">
                      <p>Return</p>
                      <p
                        onClick={() => {
                          setChooseReturn(true);
                          setBookingType(2);
                        }}
                      >
                        Choose Date
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              {bookingType === 3 && (
                <div className="flex justify-end items-center">
                  <p
                    onClick={() =>
                      setMultiCityList([
                        ...multicityList,
                        {
                          Origin: "",
                          Destination: "",
                          FlightCabinClass: 1,
                          PreferredDepartureTime: null,
                          PreferredArrivalTime: null,
                          returnDate: null,
                        },
                      ])
                    }
                    className="  text-center bg-primary hover:bg-primaryhover px-4 py-1 cursor-pointer text-white "
                  >
                    + Add City
                  </p>
                </div>
              )}
              <div className="grid grid-cols-1 border border-primary rounded-md shadow-sm shadow-gray-200  p-2 max-h-[300px] min-h-[100px] overflow-y-scroll md:grid-cols-5 place-items-center gap-x-6 mt-3">
                {multicityList.map((i, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex col-span-3 justify-around items-center font-semibold w-full gap-x-4"
                      >
                        <div className="w-full">
                          <AutoComplete
                            dropdownStyle={{ width: "450px" }}
                            placeholder="From"
                            className="w-full mt-7"
                            options={airportList}
                            value={multicityList[index].Origin.name}
                            onSearch={(value) => {
                              let temp = [...multicityList];
                              temp[index] = {
                                ...multicityList[index],
                                Origin: { name: value, value: "" },
                              };
                              setMultiCityList(temp);
                              setSearchStr({
                                value: value,
                                index: index,
                                type: "Origin",
                              });
                            }}
                          />
                        </div>
                        <HiOutlineSwitchHorizontal
                          className="cursor-pointer text-primary font-extralight mt-8"
                          size={40}
                        />
                        <div className="w-full">
                          <AutoComplete
                            dropdownStyle={{ width: "450px" }}
                            placeholder="To"
                            className="w-full mt-7"
                            options={airportList}
                            value={multicityList[index].Destination.name}
                            onSearch={(value) => {
                              let temp = [...multicityList];
                              temp[index] = {
                                ...multicityList[index],
                                Destination: { name: value, value: "" },
                              };
                              setMultiCityList(temp);
                              setSearchStr({
                                value: value,
                                index: index,
                                type: "Destination",
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full mt-7">
                        <Select
                          placeholder="Cabin Class"
                          className="w-full "
                          allowClear
                          value={i.FlightCabinClass}
                          onChange={(val) => {
                            let data = [...multicityList];
                            data[index] = {
                              ...data[index],
                              FlightCabinClass: val,
                            };
                            setMultiCityList(data);
                          }}
                          options={TravelClass}
                        />
                      </div>
                      <div className=" font-semibold w-full gap-4">
                        <div className="relative w-full">
                          <DatePicker
                            rootClassName="datepicker"
                            popupClassName="bottom-calendar"
                            allowClear={false}
                            className="w-full datepicker mt-7"
                            // onChange={() => {}}
                            // value={dayjs(multicityList[index]?.PreferredDepartureTime)}
                            onChange={(val) => {
                              let temp = [...multicityList];
                              temp[index] = {
                                ...multicityList[index],
                                PreferredDepartureTime: convertDate(
                                  new Date(val.$d).toISOString()
                                ),
                                PreferredArrivalTime: convertDate(
                                  new Date(val.$d).toISOString()
                                ),
                              };
                              setMultiCityList(temp);
                            }}
                            // onChange={
                            //   (val) => {
                            //     let data = [...multicityList];
                            //     data[index] = {
                            //       ...data[index],
                            //       PreferredArrivalTime: convertDate(
                            //         new Date(val.$d).toISOString()
                            //       ),
                            //       PreferredDepartureTime: convertDate(
                            //         new Date(val.$d).toISOString()
                            //       ),
                            //     };
                            //     setMultiCityList(data);
                            //   }
                            // setDepartureDate(
                            //   convertDate(new Date(val.$d).toISOString())
                            // )
                            // }
                            placeholder="Depart"
                          />
                          {index !== 0 && (
                            <Tooltip
                              title="Remove "
                              placement="topRight"
                              color="gray"
                            >
                              <div
                                onClick={() => handleRemove(index)}
                                className="flex absolute top-0 bottom-8 right-0 justify-end items-center cursor-pointer"
                              >
                                <AiOutlineCloseCircle className="text-end text-primary" />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
          {/* <div className="flex justify-center items-center mt-4">
            <Button
              onClick={handleGetFare}
              className="rounded-none bg-primary text-white font-bold"
            >
              Get Calender Fare
            </Button>
          </div> */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 place-items-center gap-4 mt-4">
            <div className="w-full select-none grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
              <div className=" flex justify-start gap-2 items-center w-full">
                <p className="text-white">Adults</p>
                <p className="text-center bg-white w-6 h-6 flex justify-center items-center rounded-full text-black ">
                  {adults}
                </p>
              </div>
              <div className="flex cursor-pointer gap-4 justify-around items-center">
                <p
                  onClick={() => handleSubtract("adult")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormSubtract />
                </p>
                <p
                  onClick={() => handleAdd("adult")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormAdd />
                </p>
              </div>
            </div>
            <div className="w-full select-none grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
              <div className=" flex justify-start gap-1 items-center w-full">
                <p className="text-white">Childs</p>
                <p className="text-center bg-white w-6 h-6 flex justify-center items-center rounded-full text-black ">
                  {childrens}
                </p>
              </div>
              <div className="flex cursor-pointer gap-4 justify-around items-center">
                <p
                  onClick={() => handleSubtract("children")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormSubtract />
                </p>
                <p
                  onClick={() => handleAdd("children")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormAdd />
                </p>
              </div>
            </div>
            <div className="w-full select-none bg-primary grid grid-cols-2 gap-3 col-span-1  p-2 mt-4">
              <div className=" flex justify-start gap-2 items-center w-full">
                <p className="text-white">Infant</p>
                <p className="text-center bg-white w-6 h-6 flex justify-center items-center rounded-full text-black ">
                  {infant}
                </p>
              </div>
              <div className="flex cursor-pointer gap-4 justify-around items-center">
                <p
                  onClick={() => handleSubtract("infant")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormSubtract />
                </p>
                <p
                  onClick={() => handleAdd("infant")}
                  className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
                >
                  <GrFormAdd />
                </p>
              </div>
            </div>
            <div className="w-full col-span-1">
              <Select
                placeholder="Cabin Class"
                className="w-full mt-2"
                allowClear
                value={multicityList[0].FlightCabinClass}
                onChange={(val) => {
                  let data = [...multicityList];
                  data[0] = {
                    ...data[0],
                    FlightCabinClass: val,
                  };
                  setMultiCityList(data);
                }}
                // setJourneyType(val)}
                options={TravelClass}
              />
            </div>
            <div className="col-span-1 w-full">
              <Button
                onClick={handleSearch}
                className="w-full border-none flex justify-center items-center gap-x-3 outline-none bg-primary rounded-none hover:bg-primaryhover font-bold text-white mt-2"
              >
                <FaSearch />
                Search Flights
              </Button>
            </div>
          </div>
        </div>
        {pageLoader && (
          <>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
            <div class="h-14 mt-4 animate-pulse bg-gray-400 rounded-lg dark:bg-gray-700 w-full "></div>
          </>
        )}

        { flightResult?.result?.length > 0 ? (
          bookingType === 1 || bookingType === 3 ? (
            <FlightLists data={ flightResult} />
          ) : (
            <FlightRoundWayLists data={flightResult} />
          )
        ) : (
          !pageLoader && (
            <div className="flex justify-center flex-col mt-14 items-center h-1/2">
              <img
                src={Images.PlaneIllus}
                alt="flight"
                className="w-[200px]"
                loading="lazy"
              />
              <p className="font-bold font-serif mt-2 text-xl ">
                No Data Found
              </p>
            </div>
          )
        )}
      </Spin>
    </>
  );
};

export default Flights;
