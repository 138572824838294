import React, { useEffect, useState } from "react";
import { dummydata } from "./FlightEndpoint";
import FlightDetailDrawer from "./FlightDetailDrawer";
import { getAMPM, trimString } from "../../../../Utils";
import { Tooltip } from "antd";
import { Images } from "../../../../Controller";
import { useNavigate } from "react-router-dom";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
dayjs.extend(duration);

const FlightLists = ({ data }) => {
  const navigate = useNavigate();
  const [OpenDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [detail, setDetail] = useState(null);
  const [routeTabs, setRouteTabs] = useState([]);
  function convertMinutesToTimeString(start, end) {
    const startTime = dayjs(start);
    const endTime = dayjs(end);

    const diff = endTime.diff(startTime);
    const durationObj = dayjs.duration(diff);

    const hours = Math.floor(durationObj.asHours());
    const minutes = durationObj.minutes();

    const formattedDuration = `${hours}h ${minutes}m`;
    return formattedDuration;
  }
  const AddAllDuration = (array) => {
    return array?.reduce((a, b) => {
      a = a + b.duration;
      return a;
    }, 0);
  };
  useEffect(() => {
    if (data?.result?.length > 0) {
      const tabs = data?.result[0][0]?.segments?.map((i, index) => {
        return {
          key: index,
          route: (
            <p>
              {i[0].origin?.airport?.airportCode}-
              {i[i.length - 1].destination?.airport?.airportCode}
            </p>
          ),
          time: <p>{new Date(i[0].origin?.depTime).toDateString()}</p>,
        };
      });
      setRouteTabs(tabs);
    }
  }, [data]);
  return (
    <>
      <div
        className={`mt-8  w-1/2  rounded-lg md:grid grid-cols-1 md:grid-cols-2 place-items-start md:place-items-center gap-y-4`}
      >
        {routeTabs?.map((i, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setCurrentTab(index);
              }}
              className={`w-full rounded-tr-2xl rounded-tl-2xl ${
                i.key === currentTab ? "bg-primary" : "bg-white"
              }  text-center  p-1 cursor-pointer`}
            >
              <p
                className={`cursor-pointer font-bold text-lg transition-all
                 
                ${i.key === currentTab ? "font-bold" : ""}  ${
                  i.key === currentTab ? " tab-active  text-white" : ""
                } `}
              >
                {i.route}
              </p>
              <p
                className={`font-semibold ${
                  i.key === currentTab ? " tab-active  text-white" : ""
                }  text-sm  p-0`}
              >
                {i.time}
              </p>
            </div>
          );
        })}
      </div>
      {data?.result[0]?.length > 0 &&
        data?.result[0]?.map((i, index) => {
          const Origin = i?.segments[currentTab][0]?.origin?.airport;
          const OriginDeparture = i?.segments[currentTab][0]?.origin;
          const Destination =
            i?.segments[currentTab][i.segments[currentTab]?.length - 1]
              ?.destination?.airport;
          const DestinationArrival =
            i?.segments[currentTab][i.segments[currentTab]?.length - 1]
              ?.destination;
          const airline = i?.segments[currentTab][0]?.airline;
          return (
            <div
              key={index}
              className="w-full relative rounded-sm shadow-sm shadow-gray-200 mt-2 p-4 bg-white"
            >
              <p className="absolute right-2 top-0 text-xs text-green-800">
                {i?.isLCC ? "LCC" : "Non-LCC"}
              </p>
              <p className="absolute right-2 bottom-0 text-xs text-green-800">
                {i?.isRefundable ? "Refundable" : "Non-Refundable"}
              </p>
              <div className="grid  grid-cols-6 place-items-start items-center gap-x-3">
                <div>
                  <div className="flex justify-start items-center gap-x-2">
                    <img
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = Images.FlightImage;
                        e.target.alt = "noImage";
                      }}
                      src={i?.airlineLogo ? i?.airlineLogo : Images.FlightImage}
                      className="w-10"
                      alt="flight "
                    />
                    <div>
                      <Tooltip title={airline?.airlineName}>
                        <p className="text-sm cursor-pointer font-bold">
                          {trimString(airline?.airlineName, 13)}
                        </p>
                      </Tooltip>
                      <p className="font-semibold text-sm text-gray-500">
                        {airline?.airlineCode}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-lg font-bold">
                    <span className="font-semibold text-sm">
                      {Origin?.cityCode}
                    </span>{" "}
                    {new Date(OriginDeparture?.depTime).getHours() +
                      ":" +
                      new Date(OriginDeparture?.depTime).getMinutes()}
                    <span className="text-xs font-medium text-gray-500">
                      &nbsp;
                      {getAMPM(new Date(OriginDeparture?.depTime).getHours())}
                    </span>
                  </p>
                  <p className="font-semibold text-sm text-gray-500">
                    {trimString(Origin?.cityName)} {Origin?.countryName}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 font-bold">
                    {convertMinutesToTimeString(
                      OriginDeparture?.depTime,
                      DestinationArrival?.arrTime
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-lg font-bold">
                    <span className="font-semibold text-xs">
                      {Destination?.cityCode}
                    </span>{" "}
                    {new Date(DestinationArrival?.arrTime).getHours() +
                      ":" +
                      new Date(DestinationArrival?.arrTime).getMinutes()}
                    <span className="text-xs font-medium text-gray-500">
                      &nbsp;
                      {getAMPM(
                        new Date(DestinationArrival?.arrTime).getHours()
                      )}
                    </span>
                  </p>
                  <p className="font-semibold text-sm text-gray-500">
                    {Destination?.cityName} {Destination?.countryName}
                  </p>
                </div>
                <div>
                  <p className="text-xs font-bold">
                    ₹ {i?.fare?.publishedFare ?? 0}
                  </p>
                  <p className="text-xs text-red-600">
                    {i?.segments[0][0]?.noOfSeatAvailable} Seat Left
                  </p>
                </div>
                <div>
                  <button
                    onClick={() =>
                      navigate("/flight-book", {
                        state: {
                          data: [i],
                          traceId: data.traceId,
                        },
                      })
                    }
                    className="text-sm font-bold bg-primary text-white rounded-md px-4 py-1"
                  >
                    BOOK
                  </button>
                  <p
                    onClick={() => {
                      setDetail(i?.segments[currentTab]);
                      setOpenDetailDrawer(true);
                    }}
                    className="font-semibold text-sm mt-2 cursor-pointer text-primary"
                  >
                    Flight Details{" "}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      {OpenDetailDrawer && (
        <FlightDetailDrawer
          open={OpenDetailDrawer}
          setOpen={setOpenDetailDrawer}
          detail={detail}
        />
      )}
    </>
  );
};

export default FlightLists;
