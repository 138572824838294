import { Skeleton, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../../Theme/theme";
import MerchantDetails from "./MerchantDetails";
import { BiSolidUser } from "react-icons/bi";
import { AiFillBank, AiFillCheckCircle } from "react-icons/ai";
import { IoIosBusiness } from "react-icons/io";

import { HiDocument } from "react-icons/hi";
import DocumentUpload from "./DocumentUpload";
import StatusScreen from "./StatusScreen";
import PersonalDetail from "./PersonalDetails";
import BankDetails from "./BankDetails";
import { getAllAepsMasters } from "../../../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearOnboardDetail } from "../../../../../Store/B2bslice";

const AepsOnboard = () => {
  const dispatch=useDispatch()
  const [current, setCurrent] = useState(0);
  const [states, setstates] = useState([]);
  const [usertype, setusertype] = useState([]);
  const [accountype, setAccounttype] = useState([]);
  const [banks, setBanks] = useState([]);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  const steps = [
    {
      title: " Personal Details",
      content: (
        <PersonalDetail
          current={current}
          states={states}
          setCurrent={setCurrent}
          steps={[1, 2, 3, 4, 5]}
        />
      ),
    },
    {
      title: " Merchant Details",
      content: (
        <MerchantDetails
          states={states}
          companytype={usertype}
          current={current}
          setCurrent={setCurrent}
          steps={[1, 2, 3, 4, 5]}
        />
      ),
    },
    {
      title: " Bank Details",
      content: (
        <BankDetails
          banks={banks}
          accounttype={accountype}
          current={current}
          setCurrent={setCurrent}
          steps={[1, 2, 3, 4, 5]}
        />
      ),
    },
    {
      title: "Document Uploads",
      content: (
        <DocumentUpload
          current={current}
          setCurrent={setCurrent}
          steps={[1, 2, 3, 4, 5]}
        />
      ),
    },
    {
      title: "Status",
      content: <StatusScreen />,
    },
  ];
  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    status: current !== index && "wait",
    icon:
      index === 0 ? (
        <BiSolidUser color={ThemeColor.primary} />
      ) : index === 1 ? (
        <IoIosBusiness
          color={
            current === index || current > index
              ? ThemeColor.primary
              : ThemeColor.bsgray300
          }
        />
      ) : index === 2 ? (
        <AiFillBank
          color={
            current === index || current > index
              ? ThemeColor.primary
              : ThemeColor.bsgray300
          }
        />
      ) : index === 3 ? (
        <HiDocument
          color={
            current === index || current > index
              ? ThemeColor.primary
              : ThemeColor.bsgray300
          }
        />
      ) : (
        <AiFillCheckCircle
          color={
            current === index || current > index
              ? ThemeColor.primary
              : ThemeColor.bsgray300
          }
        />
      ),
  }));
  const getMasters = () => {
    getAllAepsMasters()
      .then((res) => {
        setBanks(
          res.data.banks?.map((i, index) => {
            return {
              label: i.bank_name,
              value: i.id,
              key: i.id,
              title:i.bank_branch_name
            };
          })
        ),
          setstates(
            res.data.state?.map((i, index) => {
              return {
                label: i.stateName,
                value: i.stateId,
                key: i.stateId,
              };
            })
          ),
          setusertype(
            res.data.user_type?.map((i, index) => {
              return {
                label: i.type,
                value: i.id,
                key: i.id,
              };
            })
          );
        setAccounttype(
          res.data.account_name.map((i, index) => {
            return {
              label: i,
              value: i,
              key: index,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (memberServices.includes("8")) {
      if (
        Object.keys(profiledetail).length !== 0 &&
        profiledetail.aeps_onboard
      ) {
        navigate("/dashboard");
      } else {
        getMasters();
      }
    } else {
      navigate("/not-available");
    }
return()=>dispatch(clearOnboardDetail())
  }, []);
  return (
    <>
      <div className="bg-white p-4 rounded-md">
        <p className="text-[15px] font-bold mb-4">Merchant Onboarding</p>
        <Steps responsive size="small" current={current} items={items} />
        <div>{steps[current].content}</div>
      </div>
    </>
  );
};

export default AepsOnboard;
