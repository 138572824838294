import ChekItOut from "../Common/ChekItOut";
import AepsOnboard from "../Views/Main/BankingService/AEPSICICI/AEPSONBOARD/index";
import Aeps from "../Views/Main/BankingService/AEPSICICI/AEPS/Aeps";
import GetRemitter from "../Views/Main/DMT/GetRemitter";
import KycForm from "../Views/Main/DMT/KycForm";
import RemitterReg from "../Views/Main/DMT/RemitterReg";
import Dashboard from "../Views/Main/Dashboard";
import Busticket from "../Views/Main/OtherServices/Bus/Busticket";
import Flights from "../Views/Main/OtherServices/Flights/Flight";
import Hotel from "../Views/Main/OtherServices/Hotel/Hotel";
import Verification from "../Views/Main/Payout/Verification";
import Profile from "../Views/Main/Profile";
import RechargeAndBill from "../Views/Main/Recharge&Bill";
import Uti from "../Views/Main/UTI";
import ProtectedAuth from "./PrivateAuth";
import EkycIndex from "../Views/Main/BankingService/AEPSICICI/AepsEkyc/EkycIndex";
import WithdrawalMoney from "../Views/Main/BankingService/AEPSICICI/AEPS/WithdrawalMoney";
import Addmembers from "../Views/Main/Members/Addmembers";
import { AepsCashWithdrawl } from "../ApiServices/Apifun";
import Ministatement from "../Views/Main/BankingService/AEPSICICI/AEPS/Ministatement";
import AadharPay from "../Views/Main/BankingService/AEPSICICI/AEPS/AadharPay";
import BalanceEnquiry from "../Views/Main/BankingService/AEPSICICI/AEPS/BalanceEnquiry";
import FundRequest from "../Views/Main/FundRequest/FundRequest";
import { Images } from "../Controller";
import Default from "./404";
import NotAvailable from "./NotAvailable";
import CashWithdrawlReport from "../Views/Main/Reports/CashwithdrwalReport";
import MiniStatementReport from "../Views/Main/Reports/MinistatementReport";
import AadharPayReport from "../Views/Main/Reports/AadharPayRepoer";
import PayoutReport from "../Views/Main/Reports/PayoutReport";
import RechargeReport from "../Views/Main/Reports/RechargeReport";
import BbpsReport from "../Views/Main/Reports/BbpsReports";
import DmtReport from "../Views/Main/Reports/DmtReport";
import UtiReport from "../Views/Main/Reports/UtiReport";
import WalletReport from "../Views/Main/Reports/WalletReport";
import FundRequestReport from "../Views/Main/Reports/FundRequestReport";
import Upi from "../Views/Main/UPI/Upi";
import KycStatus from "../Views/Main/Dashboard/KycStatus";
import ComingSoon from "./ComingSoon";
import MemberLists from "../Views/Main/Members/MemberList";
import ExpressPayout from "../Views/Main/ExpressPAyout/ExpressPayout";
import XpressPayoutReport from "../Views/Main/Reports/XpressPayoutReport";
import BalanceEnquireyReport from "../Views/Main/Reports/BalanceEnquireyReport";
import PanServices from "../Views/Main/PanServices";
import NsdlReport from "../Views/Main/Reports/NsdlReport";
import AepsYesOnboard from "../Views/Main/BankingService/AEPSYES/AEPSONBOARD";
import YesBankBioMetric from "../Views/Main/BankingService/AEPSYES/AepsEkyc/EkycIndex";
import YesBankAepsReport from "../Views/Main/Reports/YesBankAepsReport";
import AepsYesMainScreen from "../Views/Main/BankingService/AEPSYES/AEPS/Index";
import GiveTopup from "../Views/Main/GiveTopUp/GiveTopup";
import MainTab from "../Views/Main/Recharge&Bill/MainTab";
import Prepaid from "../Views/Main/Recharge&Bill/Prepaid";
import Memberkyc from "../Views/Main/BankingService/AEPSYES/MemberKyc/Memberkyc";
import MAtmReport from "../Views/Main/Reports/MAtmReport";
import FlightBook from "../Views/Main/OtherServices/Flights/FlightBook";
import FlightReport from "../Views/Main/Reports/FlightReport";
import BookingDetail from "../Views/Main/OtherServices/Flights/BookingDetail";

import UpiForm from "../Views/Main/UPIDMT/UpiForm";
import UpiDmtReport from "../Views/Main/Reports/UpidmtReport";
import TravelSetting from "../Views/Main/OtherServices/TravelSetting";
import PayWorldFlight from "../Views/Main/PayWorldFlight/PayWorldFlight";
export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/kycStatus",
          element: <KycStatus />,
        },
        {
          path: "/fundRequest",
          element: <FundRequest />,
        },

        // RECHARGE ROUTES STARTED
        {
          path: "/recharge",
          element: <Prepaid />,
        },
        {
          path: "/bbps",
          element: <RechargeAndBill />,
        },
        {
          path: "/checkOut",
          element: <ChekItOut />,
        },
        // RECHARGE ROUTES ENDED
        // {
        //   path: "/transactionStatus",
        //   element: <TransactionSuccess />,
        // },

        //AEPS ROUTES STARTED
        // {
        //   path: "/banking/aeps-yesbank",
        //   element: <AepsYesMainScreen />,
        // },
        {
          path: "/banking/aeps-onboard",
          element: <AepsOnboard />,
        },
        // {
        //   path: "/banking/aepsyes-memberkyc",
        //   element: <Memberkyc />,
        // },
        // {
        //   path: "/banking/aepsyes-onboard",
        //   element: <AepsYesOnboard />,
        // },
        {
          path: "/banking/aeps",
          element: <Aeps />,
        },
        {
          path: "/banking/aeps-ekyc",
          element: <EkycIndex />,
        },
        // {
        //   path: "/banking/aepsyes-ekyc",
        //   element: <YesBankBioMetric />,
        // },
        {
          path: "/banking/aeps-cash-withdrawl",
          element: <WithdrawalMoney />,
        },
        {
          path: "/banking/aeps-aadharpay",
          element: <AadharPay />,
        },
        {
          path: "/banking/aeps-miniStatement",
          element: <Ministatement />,
        },
        {
          path: "/banking/aeps-balanceEnquiry",
          element: <BalanceEnquiry />,
        },
        //AEPS ROUTES ENDED
        // {
        //   path: "/upi",
        //   element: <Upi />,
          
        // },
        // {
        //   path: "/upi-dmt",
        //   element: <UpiForm />,
        // },
        //REPORTS ROUTES STARTED
        {
          path: "/Report/cash-withdrawl",
          element: <CashWithdrawlReport />,
        },
        {
          path: "/Report/balance-enquirey",
          element: <BalanceEnquireyReport />,
        },
        {
          path: "/Report/mini-statement",
          element: <MiniStatementReport />,
        },
        {
          path: "/Report/aadharpay",
          element: <AadharPayReport />,
        },
        {
          path: "/Report/payout",
          element: <PayoutReport />,
        },
        {
          path: "/Report/recharge",
          element: <RechargeReport />,
        },
        {
          path: "/Report/bbps",
          element: <BbpsReport />,
        },
        {
          path: "/Report/dmt",
          element: <DmtReport />,
        },
        {
          path: "/Report/uti",
          element: <UtiReport />,
        },
        {
          path: "/Report/wallet",
          element: <WalletReport />,
        },
        {
          path: "/Report/wallet",
          element: <WalletReport />,
        },
        {
          path: "/Report/nsdl",
          element: <NsdlReport />,
        },
        {
          path: "/Report/fund-request",
          element: <FundRequestReport />,
        },
        {
          path: "/Report/xpressPayout",
          element: <XpressPayoutReport />,
        },
        // {
        //   path: "/Report/m-atm",
        //   element: <MAtmReport />,
        // },
        {
          path: "/Report/yesBank",
          element: <YesBankAepsReport />,
        },
        // {
        //   path: "/Report/upi-dmt",
        //   element: <UpiDmtReport />,
        // },
        {
          path: "/Report/flight",
          element: <FlightReport />,
        },
        // REPORTS ROUTES ENDED

        //DMT ROUTES STARTED
        {
          path: "/dmt/submitKyc",
          element: <KycForm />,
        },
        {
          path: "/dmt/remitters",
          element: <GetRemitter />,
        },
        {
          path: "/dmt/addRemitters",
          element: <RemitterReg />,
        },
        //DMT ROUTES ENDED

        //PAYOUT ROUTE
        {
          path: "/payout/verification",
          element: <Verification />,
        },
        //express payout ROUTE
        {
          path: "/express-payout",
          element: <ExpressPayout />,
        },

        // UTI ROUTE
        {
          path: "/uti",
          element: <Uti />,
        },
        {
          path: "/panServices",
          element: <PanServices />,
        },

        // {
        //   path: "/history/:id",
        //   element: <Invoice />,
        // },

        // TRAVEL ROUTES STARTED
        {
          path: "/flights",
          element: <Flights />,
        },
        {
          path: "/booking-details",
          element: <BookingDetail />,
        },
        {
          path: "/flight-book",
          element: <FlightBook />,
        },
        {
          path: "/pay-world-flight",
          element: <PayWorldFlight />,
        },
        {
          path: "/bus",
          element: <Busticket />,
        },
        {
          path: "/hotel",
          element: <Hotel />,
        },
        {
          path: "/travel-setting",
          element: <TravelSetting />,
        },
        // TRAVEL ROUTES ENDED

        {
          path: "/personal-info",
          element: <Profile />,
        },
        {
          path: "/add-member",
          element: <Addmembers />,
        },
        {
          path: "/memberList",
          element: <MemberLists />,
        },

        // ERROR ROUTES
        {
          path: "/not-available",
          element: <NotAvailable />,
        },
        {
          path: "/coming-soon",
          element: <ComingSoon />,
        },
        {
          path: "*",
          element: <Default />,
        },
        {
          path: "/give-topup",
          element: <GiveTopup />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
