import React from "react";
import { Images } from "../Controller";

const FlightLoader = () => {
  return (
    <>
      <div className="bg-gray-200 opacity-75 h-screen">
        <div className="flex flex-col justify-center items-center">
          <img
            src={Images.planegif}
            className="animate-pulse flex justify-center mt-4 items-center rounded-lg w-64"
          />
          <p className="text-2xl  italic mt-2 text-black">Wait for a moment.....</p>
        </div>
        <div className="-wrapper">
          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default FlightLoader;
