import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { getPayworldFrameDetail } from "./PayWorldEndpoint";
import { useNavigate } from "react-router-dom";

const PayWorldFlight = () => {
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  const formRef = useRef(null); // Reference for the form
  const navigate = useNavigate();
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const [detail, setDetail] = useState(null);

  // Fetch iframe details
  const getIframeDetail = async () => {
    try {
      const res = await getPayworldFrameDetail();
      setDetail(res);
    } catch (err) {
      console.error("Error fetching iframe details:", err);
    }
  };

  useEffect(() => {
    dispatch(setselectedTab("120"));
    if (!memberServices.includes("101")) {
      navigate("/not-Available");
    } else {
      getIframeDetail();
    }
  }, []);

  useEffect(() => {
    if (detail && formRef.current) {
      formRef.current.submit(); // Auto-submit the form when detail is available
    }
  }, [detail]);

  return (
    <div className="container-fluid">
      {/* Iframe where the result will be loaded */}
      <iframe
        id="login-iframe"
        name="login-iframe"
        ref={iframeRef}
        title="PayWorld Flight"
        style={{ width: "100%", height: "100vh", border: "0" }}
      />

      {/* Hidden form to submit POST data */}
      {detail && (
        <form
          id="login-form"
          ref={formRef}
          method="POST"
          action={detail.url || "https://travel-air.payworldindia.com/pos"}
          target="login-iframe"
          className="hidden"
        >
          <input type="hidden" name="username" value={detail.username} />
          <input type="hidden" name="password" value={detail.password} />
          <input type="hidden" name="retailercode" value={detail.retailercode || "1"} />
          <input type="hidden" name="service" value={detail.service} />
          <input type="hidden" name="commission" value={detail.commission} />
          <input
            type="hidden"
            name="accessToken"
            value={
              detail.accessToken
            }
          />
        </form>
      )}
    </div>
  );
};

export default PayWorldFlight;
