import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import { MarkupList, SetMarkup } from "./Flights/FlightFun";
import { dispatchtoast } from "../../../Utils";

const TravelSetting = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [markupListdata, setMarkupList] = useState([]);
  const [form] = Form.useForm();
  const handleSubmit = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("markup", val.markup);
    formdata.append("markup_type", val.markup_type);
    formdata.append("service_type", val.service_type);
    SetMarkup(formdata)
      .then((res) => {
        if (res.status) {
          form.resetFields();
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const getMarkupList = () => {
    MarkupList()
      .then((res) => setMarkupList(res.markupData))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMarkupList();
  }, []);
  return (
    <>
      <div className="bg-white p-3 rounded-md">
        <p className="font-bold mb-2">Travel Markup</p>
        <Form form={form} onFinish={(val) => handleSubmit(val)}>
          <div className="grid grid-cols-1 md:grid-cols-3  place-items-center gap-3">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter markup",
                },
              ]}
              className="w-full"
              labelCol={{ span: 24 }}
              name="markup"
              label="Markup"
            >
              <CommonInput placeholder={"Enter markup"} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select markup type",
                },
              ]}
              className="w-full"
              labelCol={{ span: 24 }}
              name="markup_type"
              label="Markup Type"
            >
              <Select
                placeholder="Select Option"
                options={[
                  {
                    label: "%",
                    value: 1,
                  },
                  {
                    label: "Rs",
                    value: 0,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select service type",
                },
              ]}
              className="w-full"
              labelCol={{ span: 24 }}
              name="service_type"
              label="Service Type"
            >
              <Select
                placeholder="Select Option"
                options={[
                  {
                    label: "Flight",
                    value: "flight",
                  },
                  
                ]}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end items-center">
            <Form.Item>
              <Button
                loading={btnLoading}
                htmlType="submit"
                type="primary"
                className="w-full  bg-primary text-white border-none"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      {markupListdata?.length > 0 && (
        <>
          <p className="mt-3 font-bold text-lg">Markup List</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-start gap-3 mt-2">
            {markupListdata.map((i, index) => {
              return (
                <div className="bg-white p-2 rounded-md w-full">
                  <p className="mt-1">
                    <span className="font-bold">MarkUp: </span>
                    {i.markup}
                  </p>
                  <p className="mt-1">
                    <span className="font-bold">MarkUp Type: </span>
                    {i?.markup_type==="0"?"Rs":"%"}
                  </p>
                  <p className="mt-1">
                    <span className="font-bold">Service Id: </span>
                    {i.service_id}
                  </p>
                  <p className="mt-1">
                    <span className="font-bold">Date: </span>
                    {new Date(i.created_at).toDateString()}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default TravelSetting;
