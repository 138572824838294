import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setBankDetail } from "../../../../../Store/B2bslice";
import { filterOption } from "../../../../../Utils";
const BankDetails = ({ current, steps, accounttype, banks, setCurrent }) => {
  const formref = useRef();
  const [form] = Form.useForm();
  const [showBranchName, setShowBranchName] = useState(false);
  const getbankDetail = useSelector((state) => state.B2B.bankDetail);
  const dispatch = useDispatch();
  useEffect(() => {
    if (getbankDetail) {
      formref.current.setFieldsValue({
        bank_id: getbankDetail.bank_id,
        account_name: getbankDetail.account_name,
        bank_branch_name: getbankDetail?.bank_branch_name,
      });
      if(getbankDetail?.bank_branch_name){
        setShowBranchName(true)
      }
    } else {
      formref.current.setFieldsValue({
        bank_id: "",
        account_name: "",
        bank_branch_name: "",
      });
    }
  }, []);
  const handleFinish = (val) => {
    let data = { ...val };
    if (typeof val.bank_id !== "string") {
      data = { ...data, bank_id: val.bank_id.value };
    }
    dispatch(setBankDetail(data));
    setCurrent(3);
  };
  return (
    <div className="p-4 ">
      <Form
        name="basic"
        form={form}
        ref={formref}
        initialValues={{
          remember: true,
        }}
        onFinishFailed={(err) => console.log(err)}
        onFinish={(val) => handleFinish(val)}
      >
        <p className="text-md mt-4 text-primary font-semibold">Bank Details</p>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5">
         <Form.Item
            name={`bank_id`}
            label={"Bank"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Select any one bank!",
              },
            ]}
          >
            <Select
              placeholder="Select Bank"
              showSearch
              labelInValue
              onChange={(val) => {
                console.log(val)
                if (!val.title) {
                  setShowBranchName(true);
                  form.setFieldValue("bank_branch_name", val.title);
                }
              }}
              filterOption={filterOption}
              className="w-full mt-2"
              options={banks}
            />
          </Form.Item>{" "}
          {showBranchName && (
            <Form.Item
              name={`bank_branch_name`}
              label={"Bank Branch Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Bank Branch Name!",
                },
              ]}
            >
              <Input
                className="outline-none mt-2 text-xs border w-full mb-2 border-gray-200 focus:border-primary  h-9 px-2"
                placeholder="Enter bank branch name!"
                name="bank_branch_name"
              />
            </Form.Item>
          )}
          <Form.Item
            name={`account_name`}
            label={"Account Type"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Select any one type!",
              },
            ]}
          >
            <Select
              placeholder="Select Account type"
              className="w-full mt-2"
              options={accounttype}
            />
          </Form.Item>{" "}
        </div>
        <div className="flex justify-end items-center m-2">
          <Form.Item
          //   wrapperCol={{
          //     offset: 8,
          //     span: 16,
          //   }}
          >
            {current === steps.length - 1 && (
              <Button
                className="bg-green-500 text-white border-none"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button
                className="hover:text-black bg-blue-500 text-white outline-none border-none"
                style={{
                  margin: "0 8px",
                }}
                onClick={() => setCurrent(1)}
              >
                Previous
              </Button>
            )}{" "}
            {current < steps.length - 1 && (
              <Button
                htmlType="submit"
                className="bg-primary outline-none border-none text-white"
              >
                Next
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BankDetails;
