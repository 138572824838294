import { Drawer } from "antd";
import React from "react";
import { ThemeColor } from "../../../../Theme/theme";
import { IoArrowForward } from "react-icons/io5";
import { Images } from "../../../../Controller";
import { PiSuitcaseRollingFill   } from "react-icons/pi";
import { MdEventSeat } from "react-icons/md";

const FlightDetailDrawer = ({ open, setOpen, detail }) => {
  function getTimeDifference(startTime, endTime) {
    // Parse the start and end times
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the difference in milliseconds
    const diffMs = end - start;

    // Calculate the difference in hours and minutes
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    // Format the result as "03h 05m"
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}h ${formattedMinutes}m`;
  }
  const Origin = detail[0]?.origin?.airport;
  const OriginDeparture = detail[0]?.origin;

  const Destination = detail[detail?.length - 1]?.destination?.airport;
  const airline = detail[0]?.airline;
  return (
    <>
      <Drawer
        title={<p className="text-white">Flight Details</p>}
        headerStyle={{
          background: ThemeColor.primary,
          color: ThemeColor.bswhite,
        }}
        width={800}
        open={true}
        onClose={() => setOpen(false)}
      >
        <p className="flex justify-start items-center font-bold text-xl gap-x-2">
          {Origin?.cityName} <IoArrowForward />
          {Destination?.cityName}
        </p>
        <p className="text-sn font-bold">
          {new Date(OriginDeparture?.depTime).toDateString()} • {detail?.length-1}-stop 
          {/* • 3h 15m • Economy */}
        </p>
        {detail?.map((i, index) => {
          return (
            <div key={index}>
              <div className="flex justify-start items-center gap-x-3 mt-3">
                <img
                  loading="lazy"
                  className="w-12"
                  src={Images.FlightImage}
                  alt="flight"
                />
                <p className="font-semibold text-gray-500">
                  <span className="text-lg">{i?.airline?.airlineName}</span> |{" "}
                  {i?.airline?.airlineCode} {i?.airline?.flightNumber}
                </p>
              </div>
             <div className="border border-gray-200 mt-2 rounded-lg py-4">
             <div className="grid grid-cols-3 px-4 mt-2  place-items-center   gap-x-3">
                <div className="w-full">
                  <p className="font-semibold text-gray-500">
                    {new Date(i.origin?.depTime).toDateString()}
                  </p>
                  <p className="text-2xl font-bold">
                    {new Date(i.origin?.depTime).getHours() +
                      ":" +
                      new Date(i.origin?.depTime).getMinutes()}
                  </p>
                  <p className="text-sm font-bold">
                    {i?.origin?.airport?.airportCode ?? "-"} -{" "}
                    {i?.origin?.airport?.cityName ?? "-"}
                  </p>
                  <p className="text-xs mt-1 font-semibold text-gray-500">
                    {i?.origin?.airport?.airportName ?? "-"}
                  </p>
                  <p className="text-xs mt-1 font-semibold text-gray-500">
                    Terminal {i?.origin?.airport?.terminal ?? "-"}
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-gray-500 font-semibold">
                    {getTimeDifference(
                      i.origin?.depTime,
                      i.destination?.arrTime
                    )}
                  </p>
                </div>
                <div className="w-full">
                  <p className="font-semibold text-gray-500">
                    {new Date(i.destination?.arrTime).toDateString()}
                  </p>
                  <p className="text-2xl font-bold">
                    {new Date(i.destination?.arrTime).getHours() +
                      ":" +
                      new Date(i.destination?.arrTime).getMinutes()}
                  </p>
                  <p className="text-sm font-bold">
                    {i?.destination?.airport?.airportCode ?? "-"} -{" "}
                    {i?.destination?.airport?.cityName ?? "-"}
                  </p>
                  <p className="text-xs mt-1 font-semibold text-gray-500">
                    {i?.destination?.airport?.airportName ?? "-"}
                  </p>
                  <p className="text-xs mt-1 font-semibold text-gray-500">
                    Terminal {i?.destination?.airport?.terminal ?? "-"}
                  </p>
                </div>
              </div>
              <div className="px-4 mt-2 flex justify-start items-start gap-x-2">
                <p className="font-semibold">Baggage -</p>
                <div className="flex justify-start items-center gap-x-1 font-semibold">
                  <PiSuitcaseRollingFill  />
                  <p>{i.baggage??0} Check-In</p>
                </div>
                <div className="flex justify-start items-center gap-x-1 font-semibold">
                  <MdEventSeat />
                  <p>{i.cabinBaggage??0} Cabin</p>
                </div>
              </div>
             </div>
            </div>
          );
        })}
      </Drawer>
    </>
  );
};

export default FlightDetailDrawer;
