import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  heading: "Dashboard",
  subHeading: "",
  selectedTab: "",
  // RECHARGE STATES
  profileDetail: {},
  walletBal: "",
  memberServices: [],
  personalDetail: {},
  merchantDetail: {},
  bankDetail: {},
  documents: {},
  flightSearchDetail: null,
  flightSearchResult:null,
  flightPassangerCount: null,
};

export const b2bSlice = createSlice({
  name: "B2B",
  initialState,
  reducers: {
    setHeadings: (state, action) => {
      state.heading = action.payload;
    },
    setselectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSubHeadings: (state, action) => {
      state.subHeading = action.payload;
    },
    setProfile: (state, action) => {
      state.profileDetail = action.payload;
    },
    setWalletBal: (state, action) => {
      state.walletBal = action.payload;
    },
    setMemberService: (state, action) => {
      const map = action.payload.map((i) => i.service_id);
      state.memberServices = map;
    },
    setPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    setMerchantDetail: (state, action) => {
      state.merchantDetail = action.payload;
    },
    setBankDetail: (state, action) => {
      state.bankDetail = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setFlightSearchDetail: (state, action) => {
      state.flightSearchDetail = action.payload;
    },
    setFlightSearchResult: (state, action) => {
      state.flightSearchResult = action.payload;
    },
    setFlightPassangerCount: (state, action) => {
      state.flightPassangerCount = action.payload;
    },
    clearOnboardDetail: (state) => {
      state.personalDetail = {};
      state.merchantDetail = {};
      state.bankDetail = {};
      state.documents = {};
    },
    clearAllRedux: (state) => {
      state.personalDetail = {};
      state.merchantDetail = {};
      state.bankDetail = {};
      state.documents = {};
      state.heading = "Dashboard";
      state.subHeading = "";
      state.selectedTab = "";
      state.flightPassangerCount=null,
      state.flightSearchDetail=null,
      state.flightSearchResult=null,
      state.profileDetail = {};
      state.walletBal = "";
      state.memberServices = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHeadings,
  setProfile,
  setselectedTab,
  setWalletBal,
  setSubHeadings,
  setMemberService,
  setPersonalDetail,
  setMerchantDetail,
  setBankDetail,
  clearOnboardDetail,
  setDocuments,setFlightPassangerCount,setFlightSearchDetail,setFlightSearchResult,
  clearAllRedux,
} = b2bSlice.actions;

export default b2bSlice.reducer;
