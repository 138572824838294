import hamburger from "../Assets/menu.png";
import manondesk from "../Assets/ManOnDesk.png";
import manfind from "../Assets/manfinding.png";
import circleShape from "../Assets/Beneficiarycircle.png";
import failed from "../Assets/failed.png";
import success from "../Assets/success.png";
import pending from "../Assets/pending.png";
import nodata from "../Assets/nodata.png";
import nobank from "../Assets/nobank.png";
import loginillustration2 from "../Assets/Login illustration (2).png";
import completeKYC from "../Assets/kycImage.png";
import pendingKYC from "../Assets/PendingKyc.webp";
import rejectedKYC from "../Assets/rejectedKYC.png";
import WebsiteLogo from "../Assets/Logo/350x 115 px.png";
import WebsiteSmallLogo from "../Assets/Logo/96 px icon.png";
import comingSoon from "../Assets/comingSoon.webp"
import dashboardBanner from "../Assets/aeps-service-provider.jpg"
import Finger from "../Assets/fingers count.jpg"
import yesBank from "../Assets/yes_bank.png"
import FlightImage from "../Assets/FlightImage.png"
import PlaneIllus from "../Assets/planeillus.png"
import planegif from "../Assets/plane_loading.gif"
import dummyuser from "../Assets/user (1).png"

export const Images = {
  loginillustration2,comingSoon,dashboardBanner,yesBank,
  completeKYC,
  pendingKYC,
  rejectedKYC,
  WebsiteLogo,
  WebsiteSmallLogo,
  // broadband,
  // cable,
  circleShape,
  // club,
  // DMT,
  failed,
  success,
  pending,
  manondesk,
  manfind,
  nodata,
  nobank,
  hamburger,Finger,FlightImage,PlaneIllus,planegif,dummyuser
};
