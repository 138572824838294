import React, { useEffect, useState } from "react";
import { Button, Select, Spin } from "antd";
import {
  fetchBill,
  getBbpsOperator,
  getBbpsformParams,
  payBill,
} from "../../../../ApiServices/Apifun";
import {
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
} from "../../../../Utils";
import { BBPS } from "../../../../Assets/BBPS";
import ReportDrawer from "../../../../Common/ReportDrawer";
import Content from "../../../../Common/ReceiptContent";

const CommonBBPS = (props) => {
  const { type, selection } = props;
  const [operators, setOperators] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);
  const [Btnloading, setBtnLoading] = useState(false);
  const [getFieldsLoader, setGetFieldsLoader] = useState(false);
  const [AllOerators, setAllOperators] = useState([]);
  const [billDetail, setBillDetail] = useState({});
  const [selectedOperator, setselectedOperator] = useState(null);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [PaybillResult, setPaybillResult] = useState({
    data: null,
    transaction_id: null,
  });
  const [renderField, setRenderField] = useState([]);
  const [disableAmount, setDisableAmount] = useState(false);
  const [showDetail, setShowDetail] = useState(null);
  const [isfetch, setIsFetch] = useState(0);
  const [disableOperatorField, setDisableOperatorField] = useState(false);
  const [BtnState, setBtnState] = useState("");
  const [fields, setFields] = useState({});
  const [amount, setamount] = useState("");
  useEffect(() => {
    setselectedOperator(null);
    setOperators([]);
    setRenderField([]);
    setOpenReceiptDrawer(false);
    setPaybillResult({ data: null, transaction_id: null });
    setamount("");
    setBillDetail({});
    setDisableOperatorField(false);
    setBtnLoading(false);
    setDisableAmount(false);
    setSelectLoading(false);
    setShowDetail(false);
    setFields({});
    setGetFieldsLoader(false);
    setAllOperators({});
  }, [selection]);
  const getAllOperators = () => {
    setSelectLoading(true);
    setDisableOperatorField(true);
    getBbpsOperator(type)
      .then((res) => {
        if (res.status) {
          setAllOperators(res.data);
          setOperators(
            res.data.map((item, index) => {
              return {
                label: item.billerName,
                value: item.biller_id,
                key: index,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSelectLoading(false);
        setDisableOperatorField(false);
      });
  };
  useEffect(() => {
    getAllOperators();
  }, [type]);
  useEffect(() => {
    if (selectedOperator) {
      setShowDetail(false);
      setGetFieldsLoader(true);
      getBbpsformParams(`${type + "/" + selectedOperator}`)
        .then((res) => {
          if (res.status) {
            setRenderField(res.data);
            const objectFromDataArray = res.data.reduce((obj, item) => {
              obj[item.fieldKey] = "";
              return obj;
            }, {});
            setFields(objectFromDataArray);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setGetFieldsLoader(false));
      const checktype = AllOerators.filter(
        (item, index) => item?.biller_id === selectedOperator
      )[0]?.is_fetch;
      setIsFetch(checktype);
      checktype === 1 ? setBtnState("Fetch Bill") : setBtnState("Pay Bill");
      // setIsfetch(
      //   AllOerators.filter(
      //     (item, index) => item?.biller_id === selectedOperator
      //   )[0]?.is_fetch
      // );
    }
  }, [selectedOperator]);
  const handleChange = (event, type, min, max) => {
    const { value, name } = event.target;
    let changable;
    if (type === "NUMERIC") {
      // Use a regular expression to allow only numbers
      changable = value.replace(/[^0-9]/g, "");
    } else {
      changable = value;
    }
    // Apply the min and max length limits
    const limitedNumbers = changable.slice(0, max);

    // Update the state with the sanitized value
    setFields({ ...fields, [name]: limitedNumbers });
  };
  const handeBtnSubmit = () => {
    setBtnLoading(true);
    setDisableOperatorField(true);
    if (BtnState === "Fetch Bill") {
      const formdata = new FormData();
      formdata.append("biller_id", selectedOperator);
      Object.entries(fields).map(([key, value], index) =>
        formdata.append(key, value)
      );
      fetchBill(formdata, type)
        .then((res) => {
          if (res.status) {
            setShowDetail(true);
            setBillDetail(res.data);
            setamount(res.data.amount);
            setBtnState("Pay Bill");
            setDisableAmount(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setBtnLoading(false);
          setDisableOperatorField(false);
        });
    } else {
      const formdata = new FormData();
      formdata.append("biller_id", selectedOperator);
      formdata.append("amount", amount);
      formdata.append("latitude", getLocalStorageItem("latitude"));
      formdata.append("longitude", getLocalStorageItem("longitude"));
      formdata.append(
        "account_holder_name",
        billDetail?.account_holder_name ? billDetail?.account_holder_name : ""
      );

      formdata.append(
        "biller_name",
        AllOerators.filter(
          (item, index) => item?.biller_id === selectedOperator
        )[0]?.billerName
      );
      Object.entries(fields).map(([key, value], index) =>
        formdata.append(key, value)
      );
      payBill(formdata, type)
        .then((res) => {
          if (res.status) {
            setPaybillResult({
              data: {
                "Biller Name": res.data.biller_name,
                "Acc. Holder Name": res?.data?.account_holder_name,
                Amount: res.data.amount,
                "Payment Status":
                  res.data.payment_status === 2
                    ? "Failed"
                    : res.data.payment_status === 0
                    ? "Pending"
                    : "Success",
              },
              transaction_id: res.data.transaction_id,
            });
            setOpenReceiptDrawer(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setBtnLoading(false);
          setDisableOperatorField(false);
        });
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 w place-items-start gap-3">
        <Spin spinning={getFieldsLoader}>
          <div className="prepaid bg-white w-full shadow-normal rounded-3xl   p-5">
            <div className="flex justify-between items-center">
              <p className="font-bold capitalize text-[15px] mb-2">
                {type} Payment
              </p>
              <BBPS />
            </div>
            <Spin spinning={selectLoading}>
              <div className="selectionFields ">
                <div>
                  <p className={`required text-textGray mb-2`}>
                    Select Operator
                  </p>
                  <Select
                    showSearch
                    placeholder="Select an operator"
                    filterOption={filterOption}
                    value={selectedOperator}
                    loading={selectLoading}
                    disabled={disableOperatorField}
                    onChange={(val) => {
                      setselectedOperator(val);
                      setShowDetail(true);
                    }}
                    className="w-full mb-2 "
                    options={operators}
                  />
                </div>
                {renderField.length !== 0
                  ? renderField.map((item, index) => {
                      return (
                        <div key={index}>
                          <p
                            className={`required capitalize text-textGray mt-2 text-xs mb-2`}
                          >
                            {item.paramName}
                          </p>
                          <input
                            name={item?.fieldKey}
                            value={fields[item?.fieldKey]}
                            placeholder={`Enter ${item.paramName}`}
                            minLength={Number(item?.minlength)}
                            max={Number(item?.maxlength)}
                            disabled={disableOperatorField}
                            onChange={(e) =>
                              handleChange(
                                e,
                                item.datatype,
                                item.minlength,
                                item.maxlength
                              )
                            }
                            type="text"
                            className="outline-none text-xs border w-full border-gray-200 focus:border-primary h-9 px-2 "
                          />
                        </div>
                      );
                    })
                  : ""}
                {selectedOperator && (
                  <div
                    className={`${
                      isfetch === 1 &&
                      type !== "fasttag" &&
                      BtnState === "Fetch Bill"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <p className={`required capitalize text-textGray mb-2`}>
                      Amount
                    </p>
                    <input
                      value={amount}
                      min={1}
                      disabled={disableAmount && type !== "fasttag"}
                      onChange={(e) => setamount(e.target.value)}
                      type="number"
                      className="outline-none text-xs border w-full border-gray-200 focus:border-primary  h-9 px-2 "
                    />
                  </div>
                )}
                {showDetail && Object.keys(billDetail).length !== 0 && (
                  <div className="grid mt-2 grid-cols-1 border border-primary p-2 rounded-md font-medium text-xs bg-bsgray300 sm:grid-cols-2 gap-2 place-items-start">
                    <p>
                      Name :{" "}
                      {billDetail.account_holder_name
                        ? billDetail.account_holder_name
                        : "-"}
                    </p>
                    {type !== "fasttag" ? (
                      <>
                        <p>
                          Amount : {billDetail.amount ? billDetail.amount : "-"}{" "}
                          Rs.
                        </p>
                        <p>
                          Bill Date :{" "}
                          {billDetail.bill_date ? billDetail.bill_date : "-"}
                        </p>
                        <p>
                          Due Date :{" "}
                          {billDetail.due_date ? billDetail.due_date : "-"}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </Spin>
            {selectedOperator && (
              <div className="mt-2">
                <Button
                  disabled={Object.values(fields).some((value) => value === "")}
                  loading={Btnloading}
                  onClick={handeBtnSubmit}
                  className="bg-primary w-full border-none rounded-md h-9 text-center text-white flex justify-center items-center font-bold text-md cursor-pointer"
                >
                  {/* {isFetch === 1 ? "Fetch Bill" : "Pay Bill"} */}
                  {BtnState}
                </Button>
              </div>
            )}
          </div>
        </Spin>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={PaybillResult} />}
        />
      )}
    </>
  );
};

export default CommonBBPS;
