import { Post, getAPIUrl } from "../../../../ApiServices/apiMethod";
import { flightEndpoint } from "./FlightEndpoint";

export const AirportSearch = (data, param = "") => {
    const url = getAPIUrl(flightEndpoint.airportSearch, param);
    return Post(url, data);
  };
export const GetCalenderFare = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.calenderFare, param);
  return Post(url, data);
};
export const FlightSearch = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.flightSearch, param);
  return Post(url, data);
};
export const FareRule = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.fareRule, param);
  return Post(url, data);
};
export const FareQuote = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.fareQuote, param);
  return Post(url, data);
};
export const SSR = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.ssr, param);
  return Post(url, data);
};
export const FlightTicketbook = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.flightbook, param);
  return Post(url, data);
};
export const FlightTicketLcc = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.FlightTicketLcc, param);
  return Post(url, data);
};
export const FlightTicketNonLcc = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.FlightTicketNonLcc, param);
  return Post(url, data);
};
export const ReleasePnrReq = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.ReleasePNRRequest, param);
  return Post(url, data);
};
export const FlightChangeReq = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.flightChangeRequest, param);
  return Post(url, data);
};
export const FlightCancelCharge= (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.flightCancelCharges, param);
  return Post(url, data);
};
export const getFlightReport = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.flightReport, param);
  return Post(url, data);
};
export const getChangeReqStatus = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.reqStatus, param);
  return Post(url, data);
};
export const getFlightBookingDetail = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.getBookingDetail, param);
  return Post(url, data);
};


export const SetMarkup = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.setMarkup, param);
  return Post(url, data);
};
export const MarkupList = (data, param = "") => {
  const url = getAPIUrl(flightEndpoint.markupList, param);
  return Post(url, data);
};