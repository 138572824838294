import React, { useState } from "react";
import { useSelector } from "react-redux";
import ProfileTabbar from "./ProfileTab";
import { Modal, Skeleton } from "antd";
import BankDetails from "./BankDetails";
import { BsFillPatchCheckFill } from "react-icons/bs";
import ChangePassword from "./ChangePassword";
import ChangePin from "./ChangePin";
import { MdPending } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { Images } from "../../../Controller";

const Profile = () => {
  const profile = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const [activeTab, setActiveTab] = useState(1);
  const [openImageModal, setOpenImageModal] = useState(null);
  return (
    <>
      <div className="bg-white rounded-xl">
        <div className="flex flex-wrap justify-start gap-x-8 items-start p-4">
          <div className="user_image relative">
            {profile.avatar ? (
              <img
              onError={(e)=>{
                e.target.src=Images.dummyuser
                e.target.alt="userImg"
              }}
                onClick={() => setOpenImageModal(true)}
                src={profile.avatar}
                alt="userImg"
                className="rounded-full cursor-pointer border-4 border-primary w-24 h-24"
              />
            ) : (
              <Skeleton.Avatar active={true} size={100} shape={"circle"} />
            )}
            <div className="absolute bottom-0 right-1">
              {profile.digio_kyc === "1" ? (
                <BsFillPatchCheckFill size={20} className="text-primary" />
              ) : profile.digio_kyc === "0" ? (
                <MdPending size={20} className="text-primary" />
              ) : profile.digio_kyc === "2" ? (
                <AiFillCloseCircle size={20} className="text-primary" />
              ) : (
                <AiFillCloseCircle size={20} className="text-primary" />
              )}
            </div>
          </div>
          <div className="details mt-4">
            <p className="font-bold text-xl">
              {profile.first_name ?? "-" + " " + profile.last_name ?? "-"}
            </p>
            <p className="font-semibold text-xs md:text-sm">
              {profile.email ?? "-----"}
            </p>
            <p className="font-medium text-xs">
              {profile.address??"-" + ", " + profile.zip??"-"}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <ProfileTabbar setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      {activeTab === 1 ? (
        <div className="personal_detail w-full bg-white rounded-md p-4">
          <p className="font-bold text-lg">Personal Info</p>
          <div className="border-t-2 border-primary w-1/2 md:w-1/6"></div>

          <div className="details overflow-y-scroll h-[200px] text-xs leading-6 mt-4">
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium ">Name</p>
              <p className="text-start">
                {profile.first_name + " " + profile.last_name}
              </p>
            </div>
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium ">Member I'd</p>
              <p className="text-start">{profile.member_id}</p>
            </div>
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium ">Email ID</p>
              <p className="">{profile.email ?? "-"}</p>
            </div>{" "}
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium">Mobile Number</p>
              <p className="text-start">{profile.mobile ?? "-"}</p>
            </div>{" "}
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium">Date Of Birth</p>
              <p className="text-start">{profile.dob ?? "-"}</p>
            </div>{" "}
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium">Gender</p>
              <p className="text-start">{profile.gender ?? "-"}</p>
            </div>{" "}
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium"> Parent Name</p>
              <p className="text-start">{profile.parent_name ?? "-"}</p>
            </div>
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/2 md:w-1/3 font-medium"> KYC Status</p>
              <p className="text-start">
                {profile.digio_kyc === "1"
                  ? "Completed"
                  : profile.digio_kyc === "2"
                  ? "Rejected"
                  : profile.digio_kyc === "3"
                  ? "Not Done"
                  : "Approval Pending"}
              </p>
            </div>{" "}
          </div>
        </div>
      ) : activeTab === 2 ? (
        <div className="personal_detail w-full bg-white rounded-md p-4">
          <p className="font-bold text-lg">KYC Detail</p>
          <div className="border-t-2 border-primary w-1/2 md:w-1/6"></div>
          <div className="kyc_status leading-6 text-xs h-[200px] mt-4">
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/3 font-medium ">Aadhar</p>
              <p className="text-start">{profile.aadhar ?? "Undefined"}</p>
            </div>{" "}
            <div className="flex flex-wrap justify-start  items-center">
              <p className="w-1/3 font-medium ">PAN</p>
              <p className="text-start">{profile.pan ?? "Undefined"}</p>
            </div>
            {/* <div className="flex justify-center h-full items-center">
              <Button
                loading={btnLoading}
                disabled={!memberServices.includes("17")}
                className="bg-green-600  text-white outline-none border-none shadow shadow-gray-500"
                onClick={handleKyc}
              >
                Do KYC
              </Button>
            </div> */}
          </div>
        </div>
      ) : activeTab === 3 ? (
        <div className="personal_detail w-full bg-white rounded-md p-4">
          <p className="font-bold text-lg">Bank Details</p>
          <div className="border-t-2 border-primary w-1/2 md:w-1/6"></div>

          <BankDetails />
        </div>
      ) : activeTab === 5 ? (
        <ChangePassword />
      ) : (
        <ChangePin />
      )}
        <Modal
          className="image-Modal"
          footer={null}
          width={256}
          onCancel={()=>setOpenImageModal(false)}
          open={openImageModal}
        >
          <img src={profile.avatar} className="w-64 h-64 rounded-full" />
        </Modal>
    </>
  );
};

export default Profile;
