export const flightEndpoint = {
  airportSearch: "Api/V1/TravelFlight/airports",
  calenderFare: "Api/V1/TravelFlight/calendarFare",
  flightSearch: "Api/V1/TravelFlight/flightSearch",
  fareRule: "Api/V1/TravelFlight/fareRule",
  fareQuote: "Api/V1/TravelFlight/fareQuote",
  ssr: "Api/V1/TravelFlight/ssr",
  flightbook:"Api/V1/TravelFlight/book",
  FlightTicketLcc:"Api/V1/TravelFlight/ticketLcc",
  FlightTicketNonLcc:"Api/V1/TravelFlight/ticketNonLCC",
  ReleasePNRRequest:"Api/V1/TravelFlight/releasePnrRequest",
  flightChangeRequest:"Api/V1/TravelFlight/sendChangeRequest",
  flightCancelCharges:"Api/V1/TravelFlight/getCancellationCharges",
  flightReport:"Api/V1/TravelFlight/flightReport",
  reqStatus:"Api/V1/TravelFlight/getChangeRequestStatus",
  getBookingDetail:"Api/V1/TravelFlight/getBookingDetails",
  setMarkup:"Api/V1/Member/setMarkup",
  markupList:"Api/V1/Member/showMarkupList"
};

