import React, { useEffect, useState } from "react";
import {
  FlightCancelCharge,
  FlightChangeReq,
  FlightTicketNonLcc,
  getChangeReqStatus,
  getFlightBookingDetail,
  ReleasePnrReq,
} from "./FlightFun";
import { Button, Input, Popconfirm, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatchtoast, getLocalStorageItem } from "../../../../Utils";
import { FaCircleInfo } from "react-icons/fa6";
import { MdCancel, MdDownload } from "react-icons/md";
import { IoIosRefresh } from "react-icons/io";
import axios from "axios";
import { Globalendpoints } from "../../../../ApiServices/global";
import { APIURL } from "../../../../ApiServices/Axios";
import { ThemeColor } from "../../../../Theme/theme";

const BookingDetail = () => {
  const [pageDetail, setPageDetail] = useState(null);
  const [completeRes, setCompleteRes] = useState(null);
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  const { bookingId, id } = location?.state || {};
  const [loader, setLoader] = useState(false);
  const [cancelCharges, setCancelcharges] = useState(null);
  const change_request_status_enum = {
    0: "Not Set",
    1: "Unassigned",
    2: "Assigned",
    3: "Acknowledged ",
    4: "Completed",
    5: "Rejected",
    6: "Closed",
    7: "Pending",
    8: "Other",
  };
  const getCancelCharges = (id) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("flight_booking_id", id);
    formdata.append("request_type", 2);
    FlightCancelCharge(formdata)
      .then((res) => {
        if (res.status) {
          setCancelcharges(res?.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
  };
  const getBookingDetail = () => {
    setLoader(true);
    const formdata = new FormData();
    if (id) {
      formdata.append("booking_id", id[currentTab]);
    } else if (bookingId) {
      formdata.append("flight_booking_id", bookingId[currentTab]);
    }
    // formdata.append("pnr", "Q7REWS");
    getFlightBookingDetail(formdata)
      .then((res) => {
        if (res.status) {
          setPageDetail(res?.data?.flightItinerary);
          setCompleteRes(res.data);
          // getCancelCharges(res?.data?.flight_booking_id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (!location?.state) {
      navigate(-1);
    } else {
      getBookingDetail();
    }
  }, [currentTab]);
  const handleRelease = () => {
    if (
      //   (completeRes?.status == "0" || completeRes?.status == "1") &&
      !pageDetail?.isLCC &&
      completeRes?.ticket_status == "0"
    ) {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("flight_booking_id", completeRes?.flight_booking_id);
      ReleasePnrReq(formdata)
        .then((res) => {
          if (res.status) {
            setLoader(false);
            getBookingDetail();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      dispatchtoast("PNR already Released");
    }
  };
  const handleConfirm = () => {
    if (
      //   (completeRes?.status == "0" || completeRes?.status == "1") &&
      !pageDetail?.isLCC &&
      completeRes?.ticket_status == "0"
    ) {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("flight_booking_id", completeRes?.flight_booking_id);
      FlightTicketNonLcc(formdata)
        .then((res) => {
          if (res.status) {
            setLoader(false);
            getBookingDetail();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      dispatchtoast("Ticket Already Confirmed");
    }
  };
  const handleCancel = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("flight_booking_id", completeRes?.flight_booking_id);
    formdata.append("request_type", 1);
    formdata.append("cancellation_type", 2);
    formdata.append("remarks", remark);
    FlightChangeReq(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast("Ticket Cancelled Successfully");
          setLoader(false);
          getBookingDetail();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const handlePartialCancel = (ticket) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("flight_booking_id", completeRes?.flight_booking_id);
    formdata.append("request_type", 2);
    formdata.append("cancellation_type", 2);
    formdata.append("remarks", remark);
    formdata.append("origin", pageDetail?.origin);
    formdata.append("destination", pageDetail?.destination);
    formdata.append("ticket_id[0]", ticket?.ticketId);
    FlightChangeReq(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setLoader(false);
          getBookingDetail();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const handleStatus = (id) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("flight_booking_id", completeRes?.flight_booking_id);
    formdata.append("change_request_id", id);
    getChangeReqStatus(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setLoader(false);
          getBookingDetail();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  return (
    <>
      <div
        className={`mt-8 grid bg-white rounded-lg  ${
          id?.length > 1 || bookingId?.length > 1
            ? "grid-cols-2"
            : "grid-cols-1"
        }  place-items-start md:place-items-center my-3 gap-y-4`}
      >
        <div className="w-full text-center">
          <p
            onClick={() => {
              setCurrentTab(0);
            }}
            className={`cursor-pointer  transition-all ${
              currentTab === 0 ? "font-bold" : ""
            }  ${
              currentTab === 0
                ? "bg-primary tab-active  text-white"
                : "border border-[#EBEBEB]"
            }  py-2 `}
          >
            Departure Ticket
          </p>
        </div>
        {id?.length > 1 ||
          (bookingId?.length > 1 && (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrentTab(1);
                }}
                className={`cursor-pointer  transition-all ${
                  currentTab === 1 ? "font-bold" : ""
                }  ${
                  currentTab === 1
                    ? "bg-primary tab-active  text-white"
                    : "border border-[#EBEBEB]"
                }  py-2 `}
              >
                Return Ticket
              </p>
            </div>
          ))}
      </div>
      <Spin spinning={loader}>
        <div className="flex justify-start items-center flex-wrap gap-3">
          {completeRes?.status == "1" && (
            <div>
              <Button
                onClick={() => {
                  setLoader(true);
                  axios
                    .get(
                      APIURL +
                        `Auth/flightTicketPrint?booking_id=${
                          id ? id : bookingId
                        }&primary_color=${ThemeColor.primary}`,
                      {
                        responseType: "blob",
                        headers: {
                          "Content-Type": "application/pdf",
                          Authorization: `Bearer ${getLocalStorageItem(
                            "access_token"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      saveAs(res.data, "file.pdf");
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setLoader(false));
                }}
                className="text-white font-semibold bg-red-700 w-[200px] mx-4   border-none hover:border-none rounded-none flex justify-center items-center gap-x-2"
              >
                <MdDownload color="white" />
                Download Ticket
              </Button>
            </div>
          )}
          {completeRes?.cancel_request_type !== "1" && (
            <Popconfirm
              onConfirm={() => {
                handleCancel();
              }}
              cancelButtonProps={{
                className: "text-black hover:text-black",
                type: "primary",
              }}
              okButtonProps={{
                className: "text-white bg-red-700",
                type: "primary",
              }}
              title={
                <p className="font-semibold">
                  Are you sure you want to cancel complete ticket?
                </p>
              }
              description={
                <div>
                  <Input
                    onChange={(e) => setRemark(e.target.value)}
                    className="border border-gray-400 h-8 text-xs rounded-none"
                    placeholder="Enter Remark"
                  />
                </div>
              }
            >
              <Button className="text-white mx-4 md:mx-0 font-semibold bg-red-700 w-[200px]   border-none hover:border-none rounded-none flex justify-center items-center gap-x-2">
                <MdCancel color="white" />
                Cancel Ticket
              </Button>
            </Popconfirm>
          )}
        </div>
        {completeRes?.cancel_request_type == "1" ? (
          <p className="flex justify-end text-2xl items-center px-4 text-red-600 font-serif">
            Cancellation Pending
          </p>
        ) : completeRes?.status != "4" &&
          completeRes?.status !== "3" &&
          completeRes?.status !== "2" &&
          completeRes?.ticket_status !== "1" ? (
          <div className="flex justify-end items-center gap-x-3 px-4">
            <Button
              onClick={handleConfirm}
              className="bg-blue-800 text-white border-none hover:border-none rounded-none"
            >
              Confirm Ticket
            </Button>
            <Button
              onClick={handleRelease}
              className="bg-red-800 text-white border-none hover:border-none rounded-none"
            >
              Release PNR
            </Button>
          </div>
        ) : completeRes?.status == "4" ? (
          <p className="flex justify-end text-2xl items-center px-4 text-red-600 font-serif">
            PNR Released
          </p>
        ) : completeRes?.status == "1" ? (
          <p className="flex justify-end text-2xl items-center px-4 text-green-600 font-serif">
            Ticket Confirmed
          </p>
        ) : completeRes?.status == "3" ? (
          <p className="flex justify-end text-2xl items-center px-4 text-red-600 font-serif">
            Ticket Cancelled
          </p>
        ) : (
          ""
        )}
        <div className="m-2 bg-white p-4">
          <div className="flex justify-between flex-wrap items-start">
            <div>
              <p className="font-semibold text-3xl font-serif">
                PNR: {pageDetail?.pnr}
              </p>
              <p className="font-semibold">
                AirlineTollFreeNo:{" "}
                {pageDetail?.airlineTollFreeNo ?? "Not Provided"}
              </p>
              <p className="font-bold">
                {pageDetail?.origin ?? ""} To {pageDetail?.destination ?? ""}
              </p>
            </div>
            <div>
              <p className="font-semibold">
                Invoice No.: {pageDetail?.invoiceNo ?? "Not Provided"}
              </p>
              <p className="font-semibold">
                Invoice Issue Date:{" "}
                {new Date(pageDetail?.invoiceCreatedOn).toLocaleString() ??
                  "Not Provided"}
              </p>
            </div>
          </div>
          <div className="bg-primary text-white mt-4 p-2">
            <p>Passanger and Ticket Information</p>
          </div>
          {pageDetail?.passenger?.map((i, index) => {
            return (
              <>
                {i.ticket &&
                !completeRes?.passengers[index]?.change_request_id ? (
                  <>
                    <div className="flex flex-col gap-y-1 justify-end items-end">
                      <Popconfirm
                        placement="topLeft"
                        onConfirm={() => {
                          handlePartialCancel(i.ticket);
                        }}
                        cancelButtonProps={{
                          className: "text-black hover:text-black",
                          type: "primary",
                        }}
                        okButtonProps={{
                          className: "text-white bg-red-700",
                          type: "primary",
                        }}
                        title={<p>Are you sure you want to cancel?</p>}
                        description={
                          <div>
                            <Input
                              onChange={(e) => setRemark(e.target.value)}
                              className="border border-gray-400 h-8 text-xs rounded-none"
                              placeholder="Enter Remark"
                            />
                          </div>
                        }
                      >
                        <Button
                          size="small"
                          className="text-white cursor-pointer my-2 font-semibold bg-red-700 border-none hover:border-none rounded-none flex justify-start items-center gap-x-1"
                        >
                          <MdCancel color="white" />
                          Cancel Ticket
                        </Button>
                      </Popconfirm>
                    </div>
                  </>
                ) : completeRes?.passengers[index]?.change_request_id ? (
                  <>
                    <div
                      key={index}
                      className="flex flex-col gap-y-1 justify-end items-end"
                    >
                      <p className="text-end py-2 font-bold">
                        <span className="text-blue-600 ">Status:</span>{" "}
                        {change_request_status_enum[
                          completeRes?.passengers[index]?.change_request_status
                        ] ?? "Check again"}
                      </p>
                      <Button
                        onClick={() =>
                          handleStatus(
                            completeRes?.passengers[index]?.change_request_id
                          )
                        }
                        size="small"
                        className="text-white cursor-pointer font-semibold bg-green-700 border-none hover:border-none rounded-none flex justify-start items-center gap-x-1"
                      >
                        <IoIosRefresh color="white" />
                        Check Cancellation Status
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div
                  key={index}
                  className="grid relative bg-white font-semibold border border-gray-300 rounded-lg mt-2 p-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-items-start gap-2"
                >
                  <div className="w-full">
                    <p>
                      Passanger Name: {i.title}. {i.firstName ?? ""}{" "}
                      {i?.lastName ?? ""}
                    </p>
                  </div>
                  <div className="w-full">
                    <p>Email: {i.email} </p>
                  </div>{" "}
                  <div className="w-full">
                    <p>Mobile: {i.contactNo} </p>
                  </div>
                  {i.ticket && (
                    <>
                      <div className="w-full">
                        <p>
                          Service Fee Display Type:{" "}
                          {i?.ticket?.serviceFeeDisplayType ?? ""}
                        </p>
                      </div>
                      <div className="w-full">
                        <p>Ticket Id: {i?.ticket?.ticketId ?? ""}</p>
                      </div>
                      <div className="w-full">
                        <p>E Ticket Number: {i?.ticket?.ticketNumber ?? ""}</p>
                      </div>
                      <div className="w-full">
                        <p>
                          Issue Date:{" "}
                          {new Date(i?.ticket?.issueDate).toLocaleString() ??
                            ""}
                        </p>
                      </div>
                      <div className="w-full">
                        <p>
                          Validating Airline:{" "}
                          {i?.ticket?.validatingAirline ?? ""}
                        </p>
                      </div>
                      {i.fare && (
                        <div className="w-full">
                          <p className="underline underline-offset-3 text-green-700">
                            Fare Detail
                          </p>

                          <div className="grid  font-semibold  mt-2  grid-cols-2 place-items-start gap-2">
                            <div className="w-full">
                              <p>Base Fare: ₹ {i.fare?.baseFare ?? ""}</p>
                            </div>
                            <div className="w-full">
                              <p>Currency: ₹ {i.fare?.currency ?? ""}</p>
                            </div>
                            <div className="w-full">
                              <p>
                                Published Fare: ₹ {i?.fare?.publishedFare ?? ""}
                              </p>
                            </div>
                            <div className="w-full">
                              <p>Tax: ₹ {i?.fare?.tax ?? ""}</p>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                      {i.baggage && (
                        <div className="w-full">
                          <p className="underline underline-offset-3 text-red-500">
                            Baggage
                          </p>
                          <div>
                            {i.baggage?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="grid  font-semibold  mt-2  grid-cols-2 place-items-start gap-2"
                                >
                                  <div className="w-full">
                                    <p>Origin: {item.origin ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>Destination: {item.destination ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>Code: {item.code ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>Price: ₹ {item.price ?? ""}</p>
                                  </div>{" "}
                                  <div className="w-full">
                                    <p>Way Type: {item.wayType ?? ""}</p>
                                  </div>{" "}
                                  <div className="w-full">
                                    <p>Weight: {item.weight ?? ""} Kg</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {i.mealDynamic && (
                        <div className="w-full">
                          <p className="underline underline-offset-3 text-red-500">
                            Meal dynamic
                          </p>
                          <div>
                            {i.mealDynamic?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="grid  font-semibold  mt-2  grid-cols-2 place-items-start gap-2"
                                >
                                  <div className="w-full">
                                    <p>Origin: {item.origin ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>Destination: {item.destination ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>Code: {item.code ?? ""}</p>
                                  </div>
                                  <div className="w-full">
                                    <p>
                                      Airline Description:{" "}
                                      {item.airlineDescription ?? ""}
                                    </p>
                                  </div>
                                  <div className="w-full">
                                    <p>Price: ₹ {item.price ?? ""}</p>
                                  </div>{" "}
                                  <div className="w-full">
                                    <p>Way Type: {item.wayType ?? ""}</p>
                                  </div>{" "}
                                  <div className="w-full">
                                    <p>Quantity: {item.quantity ?? ""}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            );
          })}
          <div className="bg-primary mt-4 text-white p-2">
            <p>Travel Information</p>
          </div>
          {pageDetail?.segments?.map((i, index) => {
            return (
              <>
                <div
                  key={index}
                  className="grid bg-white font-semibold border border-gray-300 rounded-lg mt-2 p-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-start gap-2"
                >
                  <div className="w-full">
                    <p>
                      Flight: {i?.airline?.airlineName},
                      {i?.airline?.airlineCode}
                    </p>
                  </div>
                  <div className="w-full">
                    <p>
                      Origin/Terminal: {i?.origin?.airport?.airportCode},
                      {i?.origin?.airport?.cityName}/
                      <span>{i?.origin?.airport?.terminal}</span>
                    </p>
                  </div>
                  <div className="w-full">
                    <p>Origin Airport: {i?.origin?.airport?.airportName},</p>
                  </div>
                  <div className="w-full">
                    <p>
                      Destination/Terminal:{" "}
                      {i?.destination?.airport?.airportCode},
                      {i?.destination?.airport?.cityName}/
                      <span>{i?.destination?.airport?.terminal}</span>
                    </p>
                  </div>
                  <div className="w-full">
                    <p>
                      Destination Airport:{" "}
                      {i?.destination?.airport?.airportName},
                    </p>
                  </div>
                  <div className="w-full">
                    <p>Craft: {i?.craft ?? ""}</p>
                  </div>
                  <div className="w-full">
                    <p>Flight Number: {i?.airline?.flightNumber ?? ""}</p>
                  </div>
                  <div className="w-full">
                    <p>Airline PNR: {i?.airlinePNR ?? ""}</p>
                  </div>
                  <div className="w-full">
                    <p>Flight Status: {i?.flightStatus ?? ""}</p>
                  </div>
                  <div className="w-full">
                    <p>Booking Id: {pageDetail?.bookingId ?? ""}</p>
                  </div>
                </div>
              </>
            );
          })}
          <div className="bg-primary mt-4 text-white p-2">
            <p>Fare And Additional Information</p>
          </div>
          <div className="grid bg-white font-semibold  border border-gray-300 rounded-lg  mt-2 p-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center gap-2">
            <div className="w-full">
              <p>Base Fare: ₹ {pageDetail?.fare?.baseFare ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Currency: ₹ {pageDetail?.fare?.currency ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Discount: ₹ {pageDetail?.fare?.discount ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Published Fare: ₹ {completeRes?.total_fare ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Tax: ₹ {completeRes?.tax ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Offered Fare: ₹ {pageDetail?.fare?.offeredFare ?? ""}</p>
            </div>
          </div>

          <div className="bg-primary mt-4 text-white p-2">
            <p>Fare Rules</p>
          </div>
          <div className="bg-white p-4 font-semibold mt-2">
            {pageDetail?.fareRules?.map((i, index) => {
              return (
                <div
                  key={index}
                  className="font-semibold mt-2"
                  dangerouslySetInnerHTML={{ __html: i?.fareRuleDetail }}
                ></div>
              );
            })}
          </div>
          <div className="bg-primary mt-4 text-white p-2">
            <p className="flex justify-start items-center gap-x-2">
              <FaCircleInfo /> Cancellation Charges
            </p>
          </div>
          <div className="grid bg-white font-semibold  border border-gray-300 rounded-lg  mt-2 p-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center gap-2">
            <div className="w-full">
              <p>Refund Amount: {cancelCharges?.refundAmount ?? ""}</p>
            </div>
            <div className="w-full">
              <p>
                Cancellation charges: {cancelCharges?.cancellationCharge ?? ""}
              </p>
            </div>
            <div className="w-full">
              <p>Currency: {cancelCharges?.currency ?? ""}</p>
            </div>
            <div className="w-full">
              <p>Remarks: {cancelCharges?.remarks ?? ""}</p>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default BookingDetail;
