import React, { useEffect, useRef, useState } from "react";
import {
  AepsEkycSendOtp,
  AepsKycResnddOtp,
  AepsKycValidateOtp,
} from "../../../../../ApiServices/Apifun";
import axios from "axios";
import { Button, Form, Select } from "antd";
import {
  MantraIrisdeviceInfoAvdm,
  MantradeviceInfoAvdm,
  StartekDeviceInfo,
  convertXmlToJson,
  deviceOptions,
  dispatchtoast,
  getLocalStorageItem,
  mobilePattern,
  parseXml,
} from "../../../../../Utils";
import BioMatricModal from "./BioMatricModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../../../Store/B2bslice";
import { xml2json } from "../../../../../Utils/xml2json";
import CommonInput from "../../../../../Common/CommonInput";

const EkycIndex = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const[ekycPin,setEkycPin]=useState("")
  const [openModal, setOpenModal] = useState(false);
  const [deviceImei, setDeviceImei] = useState("");
  const [otpData, setOtpData] = useState({});
  const [disableResentOtp, setDisableResendOtp] = useState(false);
  const [showOtpView, setShowOtpView] = useState(false);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const formref = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(setselectedTab("0.2"));
    if (!location.state?.allow) {
      if (memberServices.includes("8")) {
        if (!profiledetail.aeps_onboard) {
          navigate("/banking/aeps-onboard");
        } else if (profiledetail.ekyc_status) {
          navigate("/dashboard");
        }
      } else {
        navigate("/dashboard");
      }
    }
    formref.current.setFieldsValue({
      mobile: profiledetail.mobile,
    });
  }, []);
  const handleSendOtp = (imei, value) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("device_imei", imei);
    formdata.append("mobile", value.mobile);
    AepsEkycSendOtp(formdata)
      .then((res) => {
        if (res.status) {
          setOtpData(res.data);
          setShowOtpView(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  async function handleDeviceInfo(value) {
    if (value.device === "morpho") {
      var successflag = 0;
      var url = "https://localhost:11100/getDeviceInfo";
      for (let index = 11100; index < 11110; index++) {
        try {
          const res = await axios({
            method: "DEVICEINFO", // Set the custom request method here
            url: `https://localhost:${index}/getDeviceInfo`,
            headers: {
              "Content-Type": "text/xml",
              Accept: "text/xml",
              origin: "https://localhost",
            },
          });
          if (res.status === 200) {
            const xmltojson = convertXmlToJson(res.data);
            const checkDeviceConnection = Object.values(
              xmltojson.attributes
            ).every((val) => val !== "");
            setDeviceImei(xmltojson.children[0].children[1].attributes.value);
            if (checkDeviceConnection) {
              handleSendOtp(
                xmltojson.children[0].children[1].attributes.value,
                value
              );
              successflag = 1;
              break;
            } else {
              dispatchtoast("Please connect device to get Device Info", true);
            }
          }
        } catch (error) {
          console.log(error)
        }finally{
          if (successflag === 0) {
            dispatchtoast("Start the service from system services or reconnect device", true);
          }
        }
      } 
    } else if (value.device === "mantra") {
      MantradeviceInfoAvdm((imei) => {
        setDeviceImei(imei);
        handleSendOtp(imei, value);
      });
      // try {
      //   const XML =
      //     '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" /> </PidOptions>';

      //   // Make an Axios request to the DEVICEINFO endpoint
      //   const response = await axios({
      //     method: "DEVICEINFO",
      //     url: `https://127.0.0.1:11100/rd/info`,
      //     headers: {
      //       "Content-Type": "text/xml; charset=utf-8",
      //     },
      //     data: XML,
      //   });
      //   if (response.status === 200) {
      //     const parsed = parseXml(response.data);
      //     const xmltojson = JSON.parse(
      //       xml2json(parsed).replace("undefined", "")
      //     );
      //     handleSendOtp(xmltojson.DeviceInfo.additional_info.Param[0].value);
      //   } else {
      //     dispatchtoast("Unable to fetch fingerPrint", true);
      //   }
      // } catch (error) {
      //   dispatchtoast("Unable to connect device",true)
      //   return { httpStatus: false, err: error.message };
      // }
    } else if (value.device === "iris") {
      MantraIrisdeviceInfoAvdm((imei) => {
        setDeviceImei(imei);
        handleSendOtp(imei, value);
      })
    }else if(value.device==="startek"){
      StartekDeviceInfo((imei) => {
        setDeviceImei(imei);
        handleSendOtp(imei, value);
      })
    }
  }
  useEffect(() => {
    let timer;
    if (showOtpView) {
      let a = 180;
      timer = setInterval(() => {
        if (a > 0) {
          a = a - 1;
          setSeconds(a);
        } else {
          clearInterval(timer); // Clear the interval when seconds reach 0
        }
      }, 1000);
    }
    // Clear the interval when showOtpView becomes false
    return () => {
      clearInterval(timer);
    };
  }, [showOtpView]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const handleResendOtp = () => {
    setDisableResendOtp(true);
    const formdata = new FormData();
    formdata.append("device_imei", deviceImei);
    formdata.append("primary_key_id", otpData.primaryKeyId);
    formdata.append("encode_fptxnid", otpData.encodeFPTxnId);
    AepsKycResnddOtp(formdata)
      .then((res) => {
        setOtpData(res.data);
        dispatchtoast("Otp sent successfully!");
      })
      .catch((err) => console.log(err))
      .finally(() => setDisableResendOtp(false));
  };
  const handleVerify = () => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("device_imei", deviceImei);
    formdata.append("otp", ekycPin);
    formdata.append("primary_key_id", otpData.primaryKeyId);
    formdata.append("encode_fptxnid", otpData.encodeFPTxnId);
    AepsKycValidateOtp(formdata)
      .then((res) => {
        if (res.status) {
          setOpenModal(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="bg-white rounded-2xl p-4 w-full md:w-9/12 lg:w-1/2">
        <p className="font-bold">E-KYC</p>
        {!showOtpView ? (
          <Form
            name="basic"
            form={form}
            ref={formref}
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleDeviceInfo(val)}
          >
            <Form.Item
              name={`mobile`}
              label={"Mobile"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Fill Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Please enter a valid mobile number",
                },
              ]}
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder="Enter Mobile Number"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select any one device!",
                },
              ]}
            >
              <Select
                placeholder="Select a device"
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item>{" "}
            <div className="m-2">
              <Form.Item
              //   wrapperCol={{
              //     offset: 8,
              //     span: 16,
              //   }}
              >
                <Button
                  htmlType="submit"
                  loading={btnLoading}
                  className="bg-primary p-3 w-full flex justify-center items-center outline-none border-none  text-white  rounded-full mt-4"
                >
                  Send OTP{" "}
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <div className="w-full  rounded-3xl shadow-normal mt-5 bg-white ">
            <div className="header flex flex-col justify-start items-start">
              <p className="font-semibold">Enter OTP</p>
            </div>
            {/* <div className=" mt-5 flex justify-center items-center">
              {[1, 2, 3, 4, 5, 6].map((i, index) => {
                return (
                  <>
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      type="text"
                      maxLength={1}
                      className="w-[15px] otpinput text-xs xs:w-[28px] sm:w-[35px] md:w-[40px] text-bsblackrgb bg-gray-100   mx-1 border-none focus:outline-none focus:border-none text-center"
                      value={pin[index]}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                    />
                  </>
                );
              })}
            </div> */}
           <div className="mt-2">
           <CommonInput value={ekycPin}  onInput={(e)=>{
                  const value = e.target.value.replace(/\D/g, '');
                  e.target.value = value;
                }} onChange={(e)=>setEkycPin(e.target.value)} placeholder={"Enter otp"}/>
            </div>
            <p
              onClick={() =>
                seconds <= 0 && !disableResentOtp && handleResendOtp()
              }
              className={`text-right mt-2 cursor-pointer font-semibold ${
                seconds > 0 ? "text-gray-600" : "text-blue-600"
              }`}
            >
              {seconds > 0 ? `Resend in ${formatTime(seconds)} sec` : "Resend"}
            </p>
            <Button
              loading={btnLoading}
              onClick={() => ekycPin!=="" && handleVerify()}
              className={`SignInBtn w-full ${
                ekycPin!=="" ? "cursor-pointer" : "cursor-not-allowed"
              }  mt-4 ${
                ekycPin!=="" ? "bg-primary" : "bg-bsgray"
              }  flex justify-center border-none items-center h-9 mt-10 rounded-full text-white text-[12px] font-bold`}
            >
              <p>Verify OTP</p>
            </Button>
          </div>
        )}
      </div>
      {openModal && (
        <BioMatricModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          refrence={{ deviceImei, ...otpData }}
        />
      )}
    </>
  );
};

export default EkycIndex;
